import {createElement} from "react";
import {Alertify} from "../lib/Alertify";
import {ErrorResponse, IErrorResponseProps} from "../react/ErrorResponse/Component/ErrorResponse/ErrorResponse";
import {alertify} from "../legacy/helper_alertify";
import {translate} from "../GlobalStores/Language";
import {IErrorResponse, IExceptionFragment} from "./IErrorResponse";

const langGet = await translate("handleErrorResponse");

function getDefaultErrorDisplay(): IErrorResponseProps {
    return {
        title: langGet("unknown.title"),
        message: langGet("unknown.message")
    };
}

function nicerErrorFind(latestError: IExceptionFragment, errorDisplay?: IErrorResponseProps): IErrorResponseProps {
    errorDisplay = errorDisplay || getDefaultErrorDisplay();
    errorDisplay.detail = latestError;
    switch (latestError.exception) {
        case String.raw`PHPW\Exception\WorkLock`: {
            errorDisplay.title = langGet("PHPW/Exception/WorkLock.title");
            errorDisplay.message = langGet("PHPW/Exception-WorkLock.message");
            break;
        }
        case String.raw`PHPW\Exception\ArrayToObjectException`: {
            errorDisplay.title = langGet("PHPW/Exception/ArrayToObjectException.title");
            errorDisplay.message = langGet("PHPW/Exception/ArrayToObjectException.message", {
                exception: latestError.exception,
                exceptionMessage: latestError.exceptionMessage
            });
            break;
        }
        case String.raw`PHPW\Exception\ChangedSinceOpenException`: {
            errorDisplay.title = langGet("PHPW/Exception/ChangedSinceOpenException.title");
            errorDisplay.message = langGet("PHPW/Exception/ChangedSinceOpenException.message");
            break;
        }
        case String.raw`PHPW\Exception\NodeCallerTimeoutException`: {
            errorDisplay.title = langGet("PHPW/Exception/NodeCallerTimeoutException.title");
            errorDisplay.message = langGet("PHPW/Exception/NodeCallerTimeoutException.message", {
                exception: latestError.exception,
                exceptionMessage: latestError.exceptionMessage
            });
            break;
        }
        case String.raw`PHPW\Article\Exception\RecursionException`: {
            errorDisplay.title = langGet("PHPW/Article/Exception/RecursionException.title");
            errorDisplay.message = langGet("PHPW/Article/Exception/RecursionException.message", {
                exception: latestError.exception,
                exceptionMessage: latestError.exceptionMessage
            });
            break;
        }
        case String.raw`PHPW\Mail\Exception\MailServerTookTooLongException`: {
            errorDisplay.title = langGet("PHPW/Mail/Exception/MailServerTookTooLongException.title");
            errorDisplay.message = langGet("PHPW/Mail/Exception/MailServerTookTooLongException.message");
            break;
        }
        case String.raw`PHPW\SQL\Exception\SQLQueryException`: {
            errorDisplay.title = langGet("PHPW/SQL/Exception/SQLQueryException.title");
            errorDisplay.message = langGet("PHPW/SQL/Exception/SQLQueryException.message", {
                exception: latestError.exception,
                exceptionMessage: latestError.exceptionMessage
            });
            break;
        }
        case String.raw`PHPW\Parcel\Exception\ParcelApiException`: {
            {
                errorDisplay.title = langGet("PHPW/Parcel/Exception/ParcelApiException.title");
                errorDisplay.message = langGet("PHPW/Parcel/Exception/ParcelApiException.message", {
                    exceptionMessage: latestError.exceptionMessage
                });
            }
            break;
        }
        case String.raw`PHPW\Parcel\Exception\ParcelException`: {
            {
                errorDisplay.title = langGet("PHPW/Parcel/Exception/ParcelException.title");
                errorDisplay.message = langGet("PHPW/Parcel/Exception/ParcelException.message", {
                    exception: latestError.exception,
                    exceptionMessage: latestError.exceptionMessage
                });
            }
            break;
        }
        case String.raw`PHPW\Parcel\Exception\ParcelInvalidArgumentException`: {
            {
                errorDisplay.title = langGet("PHPW/Parcel/Exception/ParcelInvalidArgumentException.title");
                errorDisplay.message = langGet("PHPW/Parcel/Exception/ParcelInvalidArgumentException.message", {
                    exceptionMessage: latestError.exceptionMessage
                });
            }
            break;
        }
        case String.raw`PHPW\Mail\Exception\ServerMailNotFoundException`: {
            errorDisplay.title = langGet("PHPW/Mail/Exception/ServerMailNotFoundException.title");
            errorDisplay.message = langGet("PHPW/Mail/Exception/ServerMailNotFoundException.message");
            break;
        }
        case String.raw`PHPW\Exception\MailsenderTimeoutException`: {
            errorDisplay.title = langGet("PHPW/Exception/MailsenderTimeoutException.title");
            errorDisplay.message = langGet("PHPW/Exception/MailsenderTimeoutException.message");
            break;
        }
        case String.raw`PHPW\ERechnung\Exception\ERechnungException`: {
            errorDisplay.title = langGet("PHPW/Exception/ERechnungException.title");
            errorDisplay.message = langGet("PHPW/Exception/ERechnungException.message", {
                exceptionMessage: latestError.exceptionMessage
            });
            break;
        }
        case String.raw`PHPW\Exception\MailsenderException`: {
            errorDisplay.title = langGet("PHPW/Exception/MailsenderException.title");
            errorDisplay.message = langGet("PHPW/Exception/MailsenderException.message", {
                exceptionMessage: latestError.exceptionMessage
            });
            break;
        }
        case String.raw`PHPW\Mail\Exception\MailImapFlagException`: {
            errorDisplay.title = langGet("PHPW/Mail/Exception/MailImapFlagException.title");
            errorDisplay.message = langGet("PHPW/Mail/Exception/MailImapFlagException.message");
            errorDisplay.onlyNotify = "warning";
            break;
        }
        case String.raw`PHPW\Mail\Exception\MailImapSentException`: {
            errorDisplay.title = langGet("PHPW/Mail/Exception/MailImapSentException.title");
            errorDisplay.message = langGet("PHPW/Mail/Exception/MailImapSentException.message");
            errorDisplay.onlyNotify = "warning";
            break;
        }
        case String.raw`PHPW\Mail\Exception\MailNoSenderException`: {
            errorDisplay.title = langGet("PHPW/Mail/Exception/MailNoSenderException.title");
            errorDisplay.message = langGet("PHPW/Mail/Exception/MailNoSenderException.message");
            break;
        }
        case String.raw`PHPW\Mail\Exception\MailNoRecipientException`: {
            errorDisplay.title = langGet("PHPW/Mail/Exception/MailNoRecipientException.title");
            errorDisplay.message = langGet("PHPW/Mail/Exception/MailNoRecipientException.message");
            break;
        }
        case String.raw`PHPW\Process\Exception\BookingException`: {
            errorDisplay.title = langGet("PHPW/Process/Exception/BookingException.title");
            errorDisplay.message = latestError.exceptionMessage;
            break;
        }
        case String.raw`PHPW\Exception\DatevImportException`: {
            errorDisplay.title = langGet("PHPW/Exception/DatevImportException.title");
            errorDisplay.message = latestError.exceptionMessage;
            break;
        }
        case String.raw`PHPW\Camt\Exception\CamtImportException`: {
            errorDisplay.title = langGet("PHPW/Camt/Exception/CamtImportException.title");
            errorDisplay.message = latestError.exceptionMessage;
            break;
        }
        case String.raw`PHPW\Exception\NoBillFoundException`: {
            errorDisplay.title = langGet("PHPW/Exception/NoBillFoundException.title");
            errorDisplay.message = langGet("PHPW/Exception/NoBillFoundException.message");
            break;
        }
        case String.raw`PHPW\Process\Exception\FinalizedException`: {
            errorDisplay.title = langGet("PHPW/Process/Exception/FinalizedException.title");
            errorDisplay.message = latestError.exceptionMessage;
            break;
        }
        case String.raw`PHPW\Process\Exception\StockException`: {
            errorDisplay.title = langGet("PHPW/Process/Exception/StockException.title");
            errorDisplay.message = latestError.exceptionMessage;
            break;
        }
        case String.raw`PHPW\Lager\Exception\InvalidStockActionException`: {
            errorDisplay.title = langGet("PHPW/Lager/Exception/InvalidStockActionException.title");
            errorDisplay.message = latestError.exceptionMessage;
            break;
        }
        case String.raw`PHPW\Lager\Exception\AlreadySolvedException`: {
            errorDisplay.title = langGet("PHPW/Lager/Exception/AlreadySolvedException.title");
            errorDisplay.message = langGet("PHPW/Lager/Exception/AlreadySolvedException.message", {
                entryId: latestError.exceptionMessage
            });
            errorDisplay.onlyNotify = "warning";
            break;
        }
        case String.raw`PHPW\Lager\Exception\NoArticleException`: {
            errorDisplay.title = langGet("PHPW/Lager/Exception/AlreadySolvedException.title");
            errorDisplay.message = langGet("PHPW/Lager/Exception/NoArticleException.message", {
                entryId: latestError.exceptionMessage
            });
            break;
        }
        case String.raw`PHPW\Lager\Exception\NoBatchSupportException`: {
            errorDisplay.title = langGet("PHPW/Lager/Exception/AlreadySolvedException.title");
            errorDisplay.message = langGet("PHPW/Lager/Exception/NoBatchSupportException.message", {
                entryId: latestError.exceptionMessage
            });
            errorDisplay.onlyNotify = "error";
            break;
        }
        case String.raw`PHPW\Lager\Exception\NoDispoPiecesSupportException`: {
            errorDisplay.title = langGet("PHPW/Lager/Exception/AlreadySolvedException.title");
            errorDisplay.message = langGet("PHPW/Lager/Exception/NoDispoPiecesSupportException.message", {
                entryId: latestError.exceptionMessage
            });
            errorDisplay.onlyNotify = "error";
            break;
        }
        case String.raw`PHPW\Lager\Exception\AmountToSerialDiffException`: {
            errorDisplay.title = langGet("PHPW/Lager/Exception/AlreadySolvedException.title");
            errorDisplay.message = langGet("PHPW/Lager/Exception/AmountToSerialDiffException.message", {
                entryId: latestError.exceptionMessage
            });
            errorDisplay.onlyNotify = "error";

            errorDisplay.overrideNotifyAction = async () =>
                (await import("../react/Mobile/Inventur/opener/mobileOpenArticle")).mobileOpenArticleDialog(
                    Number(latestError.exceptionMessage)
                );
            break;
        }
        case String.raw`PHPW\Lager\Exception\NonEmptyStockException`: {
            errorDisplay.title = langGet("PHPW/Lager/Exception/NonEmptyStockException.title");
            errorDisplay.message = langGet("PHPW/Lager/Exception/NonEmptyStockException.message", {
                stockName: latestError.exceptionMessage
            });
            break;
        }
        case String.raw`TS\Error\MailAttachment\NotFoundError`: {
            errorDisplay.title = langGet("TS/Error/MailAttachment/NotFoundError.title");
            errorDisplay.message = langGet("TS/Error/MailAttachment/NotFoundError.message", {
                file: latestError.file
            });
            break;
        }
        case String.raw`TS\Error\MailAttachment\NotReadableError`: {
            errorDisplay.title = langGet("TS/Error/MailAttachment/NotReadableError.title");
            errorDisplay.message = langGet("TS/Error/MailAttachment/NotReadableError.message", {
                file: latestError.file
            });
            break;
        }
        case String.raw`TS\Error\MailAttachment\SecurityError`: {
            errorDisplay.title = langGet("TS/Error/MailAttachment/SecurityError.title");
            errorDisplay.message = langGet("TS/Error/MailAttachment/SecurityError.message", {
                file: latestError.file
            });
            break;
        }
        case String.raw`PHPW\Calendar\Exception\TerminException`: {
            errorDisplay.title = "";
            errorDisplay.message = langGet("PHPW/Calendar/Exception/TerminException");
            errorDisplay.onlyNotify = "error";
            break;
        }
        case String.raw`PHPW\Exception\EntityFlushException`: {
            errorDisplay.title = langGet("PHPW/Exception/EntityFlushException");
            errorDisplay.message = latestError.exceptionMessage;
            break;
        }
        default: {
            errorDisplay.title = langGet("default.title");
            errorDisplay.message = langGet("default.message", {
                exception: latestError.exception,
                exceptionMessage: latestError.exceptionMessage
            });
            break;
        }
    }
    return errorDisplay;
}

function asDialog(errorDisplay: IErrorResponseProps) {
    Alertify.alert(errorDisplay.title, createElement(ErrorResponse, errorDisplay));
}

export function handleErrorResponse(response: IErrorResponse) {
    const errorDisplay = getDefaultErrorDisplay();

    if (response.message) {
        errorDisplay.message = response.message;
    }
    if (response.route) {
        errorDisplay.route = response.route;
    }
    if (response.exception?.length) {
        const latestError = response.exception.at(0);
        if (latestError) {
            errorDisplay.detail = latestError;
            nicerErrorFind(latestError, errorDisplay);
        }
        errorDisplay.chain = response.exception.slice(1).map(function (value) {
            return nicerErrorFind(value);
        });
    }

    if (errorDisplay.onlyNotify) {
        alertify.notify(String(`${errorDisplay.title}: ${errorDisplay.message}`), errorDisplay.onlyNotify, () => {
            if (errorDisplay.overrideNotifyAction) {
                errorDisplay.overrideNotifyAction();
            } else {
                asDialog(errorDisplay);
            }
        });
    } else {
        asDialog(errorDisplay);
    }
}
