export namespace PHPW {
    export enum Permissions {
        "_Entwickleroptionen" = 5,
        "_Farbeinstellungen" = 6,
        "_Farbeinstellungen_global_" = 7,
        "_Mobile_App_Schnellmenue" = 10,
        "_Mobile_App_NeuesFoto" = 11,
        "_Lagerverwaltung" = 18,
        "_Export_Liste" = 74,
        "_Export_AlleSpalten" = 76,
        "_Unbekannt_Yellowbox" = 81,
        "_Chat" = 86,
        "_Notizblock" = 87,
        "_Taschenrechner" = 88,
        "_Converter" = 89,
        "_CurrencyConverter" = 84,
        "_Barcode_MDE" = 90,
        "Optionen_Benutzerprofil" = 23,
        "Optionen_MyMenu_Einstellungen" = 24,
        "Optionen_Mailsuche" = 26,
        "Optionen_Spamsperreimportieren" = 28,
        "Optionen_Aufrufhistorie" = 29,
        "Optionen_Aenderungsverlauf" = 22,
        "Optionen_Datenimportieren" = 32,
        "Optionen_Export" = 73,
        "Optionen_AenderungenAufgabe" = 75,
        "Optionen_Dashboard" = 85,
        "Optionen_Suche" = 94,
        "Optionen_PDF_Einstellungen" = 96,
        "Administration_Benutzerverwaltung" = 30,
        "Administration_Berechtigungen1" = 31,
        "Administration_AlsUsereinloggen" = 33,
        "Administration_Administration" = 34,
        "Administration_Berechtigungen2" = 35,
        "Administration_Berechtigungsgruppen" = 36,
        "Administration_Loeschliste" = 68,
        "Administration_Hilfepflegen" = 91,
        "Administration_Backup" = 93,
        "Administration_Einstellungen" = 95,
        "Administration_Sitzungwiederherstellen" = 97,
        "Administration_NeuerLink" = 98,
        "Administration_Systemeinstellungen" = 99,
        "Administration_Infoboxberechtigungen" = 551,
        "Administration_Infodiagrammberechtigungen" = 553,
        "Administration_Infotabellenerechtigungen" = 554,
        "Administration_MailAccounts" = 1082,
        "Administration_Mail_Adressliste_Partial" = 1083,
        "Administration_Mailsetup" = 1170,
        "Dev_ProcessGroups" = 99_001,
        "Administration_Reports" = 1207,
        "Artikel_Wareneingang" = 20,
        "Artikel_Warenausgang" = 21,
        "Artikel_Preislisteimportieren" = 101,
        "Artikel_Artikelimportieren" = 102,
        "Artikel_ArtikelAufrufhistorie" = 103,
        "Artikel_ArtikelAenderungen" = 104,
        "Artikel_ArtikelVerknuepfungen" = 105,
        "Artikel_Einkaufspreisanzeigen" = 110,
        "Artikel_Verkaufspreis1anzeigen" = 111,
        "Artikel_Verkaufspreis2anzeigen" = 112,
        "Artikel_Verkaufspreis3anzeigen" = 113,
        "Artikel_UVPanzeigen" = 114,
        "Artikel_Strassenpreisanzeigen" = 115,
        "Artikel_Shop_Preisanzeigen" = 116,
        "Artikel_NeuerArtikel" = 120,
        "Artikel_nurbestehendenlesend" = 121,
        "Artikel_Artikel_Ebene1" = 122,
        "Artikel_Artikel_Ebene2" = 123,
        "Artikel_Artikel_Changelog" = 124,
        "Artikel_Bestandspflege" = 125,
        "Artikel_nichtvergeben" = 126,
        "Artikel_Chargen_Liste" = 130,
        "Artikel_Artikel_Liste" = 140,
        "Artikel_Artikelloeschen" = 141,
        "Artikel_ArtikelDatanorm" = 142,
        "Artikel_Bestandsverlauf" = 145,
        "Artikel_AllePreismodifikationen" = 146,
        "Artikel_Preismodifikationeneditieren" = 147,
        "Artikel_Preismodifikationenloeschen" = 148,
        "Artikel_Paketliste" = 150,
        "Artikel_Artikel_Maskeoeffnen" = 168,
        "Artikel_Lohnminutenbearbeiten" = 169,
        "Artikel_Shoppreisbearbeiten" = 170,
        "Artikel_Artikelbeschreibungbearbeiten" = 171,
        "Artikel_Lagerdatenbearbeiten" = 172,
        "Artikel_Lieferantenbearbeiten" = 173,
        "Artikel_Lagerbestand" = 181,
        "Artikel_Lagerbest_manuellesDatum" = 182,
        "Artikel_LagerSummenanzeige" = 183,
        "Artikel_ReserviertelagerKorrektur" = 184,
        "Artikel_ArtikelInventurOnlyOpen" = 185,
        "Artikel_ArtikelInventurOnlyOpenAdd" = 186,
        "Artikel_ArtikelInventur" = 187,
        "Artikel_ArtikelInventurAdd" = 188,
        "Artikel_ArtikelInventurCorrection" = 189,
        "Artikel_Seriennummer_Liste" = 190,
        "Artikel_Seriennummer_Maske" = 191,
        "Artikel_Seriennummern_erweitertesEditieren" = 192,
        "Kontakte_Kontakteimportieren" = 201,
        "Kontakte_contact_import" = 202,
        "Kontakte_deny_additional_tabs" = 210,
        "Kontakte_NeuerKontakt" = 220,
        "Kontakte_Kontaktebearbeiten" = 221,
        "Kontakte_AlleKennzeichen" = 230,
        "Kontakte_Kennzeichenloeschen" = 231,
        "Kontakte_Kennzeichenanlegen_editieren_Textaendern_" = 232,
        "Kontakte_Kennzeichenvergeben" = 233,
        "Kontakte_Kontakt_Kennzeichen_Aenderungen" = 235,
        "Kontakte_AlleKontakte" = 240,
        "Kontakte_VertraulicheKontakteanzeigen" = 241,
        "Kontakte_Kontaktpersonen_Maske" = 244,
        "Kontakte_AllePersonen" = 245,
        "Kontakte_geloeschtePersonenanzeigen" = 246,
        "Kontakte_NeuePaketeeintragen" = 251,
        "Kontakte_NeuePreisanfrageerstellen_einsehen" = 252,
        "Kontakte_Bearbeitungshistorie" = 260,
        "Kontakte_Loeschliste" = 270,
        "Kontakte_Kontaktloeschen" = 271,
        "Kontakte_Kontaktloeschen_unwiderruflich_" = 272,
        "Kontakte_Dublettenbearbeiten" = 280,
        "Kontakte_alleAktivitaeten" = 290,
        "Kontakte_Bankverbindungbearbeiten" = 503,
        "Projekte_ProjektSonderberechtigung1" = 301,
        "Projekte_ProjektSonderberechtigung2" = 302,
        "Projekte_ProjektSonderberechtigung3" = 303,
        "Projekte_ProjektSonderberechtigung4" = 304,
        "Projekte_ProjektSonderberechtigung5" = 305,
        "Projekte_ProjektSonderberechtigung6" = 306,
        "Projekte_ProjektSonderberechtigung7" = 307,
        "Projekte_ProjektSonderberechtigung8" = 308,
        "Projekte_ReiterohneeigeneRechteanzeigen" = 309,
        "Projekte_Projekt_Liste_MailanhaengeImport_Dialog" = 310,
        "Projekte_Projektloeschen" = 311,
        "Projekte_geloeschteProjekteanzeigen" = 312,
        "Projekte_Summenanzeige" = 313,
        "Projekte_Projektmaske" = 320,
        "Projekte_Projektbeschreibungbearbeiten" = 322,
        "Projekte_Freitextfelderbearbeiten" = 323,
        "Vorgaenge_Preisliste" = 160,
        "Vorgaenge_Sendungsnummern_Paketnummer_Liste" = 470,
        "Vorgaenge_Sendungsnummer_Paketnummererstellen" = 471,
        "Vorgaenge_Charge" = 401,
        "Vorgaenge_Vorgangsnamenaendern" = 402,
        "Vorgaenge_Positions_Extradaten" = 409,
        "Vorgaenge_Positionshistorie" = 410,
        "Vorgaenge_SummeninPositionsverlauf" = 411,
        "Vorgaenge_PreiseundSeriennummerinPartialanzeigen" = 412,
        "Vorgaenge_ArtikelstammdateninVorgangspositioneditierbar" = 413,
        "Vorgaenge_finalize" = 419,
        "Vorgaenge_NeuerVorgang" = 420,
        "Vorgaenge_Rechnungsdatumaendern" = 421,
        "Vorgaenge_Brutto_Netto_Switch" = 422,
        "Vorgaenge_SpeichernundneuerFreitextartikel" = 423,
        "Vorgaenge_FreitextartikelImmerErlauben" = 424,
        "Vorgaenge_Kasseoeffnen" = 430,
        "Vorgaenge_GAEB_import" = 436,
        "Vorgaenge_NeueEingangsrechnung" = 437,
        "Vorgaenge_Rechnungskorrektur" = 438,
        "Vorgaenge_nureigeneVorgaenge" = 439,
        "Vorgaenge_AlleVorgaenge" = 440,
        "Vorgaenge_Summenanzeige" = 441,
        "Vorgaenge_VertraulicheVorgaenge" = 442,
        "Vorgaenge_Vorgangstornieren_Rechnung" = 443,
        "Vorgaenge_VorgaengeStatussetzen" = 444,
        "Vorgaenge_Margeanzeigen" = 445,
        "Vorgaenge_Vorgangersetzen_ueberschreiben_STORNO_Dialog_" = 446,
        "Vorgaenge_Vorgangstornieren_Bestellung" = 447,
        "Vorgaenge_Vorgangstornieren_Lieferschein" = 448,
        "Vorgaenge_Vorgangstornieren_Auftragsbestaetigung" = 449,
        "Vorgaenge_ReservierteArtikelListe" = 450,
        "Vorgaenge_Vorgangstornieren_Angebot" = 451,
        "Vorgaenge_BestellteArtikel_Liste" = 452,
        "Vorgaenge_VorgangOneToOneersetzen_ueberschreiben_STORNO_Dialog_" = 453,
        "Vorgaenge_VorgangMitAbzug" = 454,
        "Vorgaenge_Rueckstandsliste" = 455,
        "Vorgaenge_Rueckstand_alt_" = 456,
        "Vorgaenge_Controlling" = 460,
        "Vorgaenge_processGroup" = 461,
        "Vorgaenge_Sammlungspeichern" = 462,
        "Vorgaenge_Sammlungladen" = 463,
        "Vorgaenge_Sammlungloeschen" = 464,
        "Vorgaenge_SammlungEdit" = 465,
        "Vorgaenge_Margen_Liste" = 480,
        "Vorgaenge_VorgangAuswertungen" = 481,
        "Vorgaenge_Shop_Schnittstelle" = 485,
        "Vorgaenge_Intervallvorgang_Dauerrechnung" = 490,
        "Vorgaenge_WichtigeHinweise" = 610,
        "Buchhaltung_Import" = 501,
        "Buchhaltung_BankUmsaetze" = 504,
        "Buchhaltung_Kostenstellen" = 502,
        "Buchhaltung_ImportAddison" = 509,
        "Buchhaltung_Kontenplan" = 510,
        "Buchhaltung_Kontoanlegen_bearbeiten_kopieren_Kontenplan_" = 511,
        "Buchhaltung_Kontoloeschen_Kontenplan_" = 512,
        "Buchhaltung_KontenplanStapeldialog" = 513,
        "Buchhaltung_Belegkreise" = 515,
        "Buchhaltung_Maske" = 520,
        "Buchhaltung_Maskenberechtigung_Stapelbuchen" = 521,
        "Buchhaltung_Maskenberechtigung_Direktbuchen" = 522,
        "Buchhaltung_ER_Maske" = 523,
        "Buchhaltung_Kassenmaske" = 524,
        "Buchhaltung_BuchungsTemplate" = 530,
        "Buchhaltung_Liste" = 540,
        "Buchhaltung_Journalbuchungenbearbeiten" = 541,
        "Buchhaltung_Buchungenloeschen" = 542,
        "Buchhaltung_BuchungenKontowechseln" = 543,
        "Buchhaltung_VertraulicheBuchungenanzeigen" = 544,
        "Buchhaltung_VertraulicheBuchungenButtonanzeigen" = 545,
        "Buchhaltung_ListeParams" = 546,
        "Buchhaltung_Konten_Abgleich" = 547,
        "Buchhaltung_Avis" = 552,
        "Buchhaltung_ERB" = 560,
        "Buchhaltung_ERBZahlungeingeben" = 561,
        "Buchhaltung_ARB" = 580,
        "Buchhaltung_ARBZahlungeingeben" = 581,
        "Buchhaltung_Vergleichsliste" = 590,
        "Buchhaltung_IntraHandel" = 595,
        "Buchhaltung_Auswertungen" = 600,
        "Buchhaltung_Berichte" = 620,
        "ERB_SummenanzeigeERB" = 562,
        "ARB_SummenanzeigeARB" = 582,
        "Auswertungen_Auswertungen2_0" = 601,
        "Auswertungen_Geschaeftsleitung_AuswertungenGeschaeftsleitung" = 602,
        "Organisation_AlleAufgaben" = 702,
        "Organisation_Aufgabenkommentare" = 730,
        "Organisation_Aufgaben_Tafel" = 703,
        "Organisation_NureigeneAufgaben" = 739,
        "Organisation_NeueAufgabe" = 704,
        "Organisation_Aufgabenliste_AufErledigtsetzen" = 705,
        "Organisation_Aufgabenliste_AufGelesensetzen" = 709,
        "Organisation_Aufgabenmaske_vertraulichsetzen" = 707,
        "Organisation_Aufgabenmaske_vertraulichoeffnen" = 706,
        "Organisation_AufgabenlisteausSichtvon" = 708,
        "Organisation_CCSKundenkommunikation" = 710,
        "Organisation_Materialverbrauch" = 711,
        "Organisation_Zeiterfassungsliste_Arbeitstag" = 92,
        "Organisation_Zeiterfassungsliste_Summenanzeige" = 761,
        "Organisation_Zeiterfassungsliste_Eintragbearbeiten" = 763,
        "Organisation_Zeiterfassung_Dialog" = 760,
        "Organisation_Zeiterfassung_Aufgabenkommentaruebertragen" = 762,
        "Organisation_Personalplanung_Kalender" = 780,
        "Organisation_Personalplanung_Liste" = 781,
        "Organisation_Personalplanung_Edit_Level1" = 782,
        "Organisation_Personalplanung_Edit_Level2" = 783,
        "Organisation_DokuWiki" = 770,
        "Termine_Kalender" = 750,
        "Termine_alleTermine" = 740,
        "Termine_Terminloeschen" = 741,
        "Termine_vertraulicheTermine" = 742,
        "Termine_vertraulicheTerminesetzen" = 743,
        "Termine_GlobalberechtigungfueralleTermine" = 744,
        "Termine_Sekretaer" = 755,
        "Einkauf_NeueBestellung" = 820,
        "Einkauf_Bestell_Liste" = 840,
        "SpamSperre_Abrechnung" = 901,
        "SpamSperre_Einstellungen" = 902,
        "Disposition_GeloeschteDispoanzeigen" = 1001,
        "Disposition_Dispoartikelimportieren" = 1002,
        "Disposition_DispoVorgaengePlan" = 1005,
        "Disposition_DispoartikelReservierungsplan" = 1010,
        "Disposition_DispoartikelBelegtplan" = 1015,
        "Disposition_Dispoartikel" = 1020,
        "Disposition_Dispoartikelfree" = 1021,
        "Disposition_DispoartikelKategorie" = 1025,
        "Disposition_Dispovorgaenge" = 1030,
        "Disposition_DispoPositionen" = 1035,
        "Disposition_DispoPositionenbis" = 1036,
        "Disposition_BackdatedPosition" = 1037,
        "Disposition_unlinkPosition" = 1038,
        "Disposition_CheckIn" = 1040,
        "Disposition_CheckOut" = 1050,
        "Disposition_DispoSNrListe" = 1060,
        "Disposition_DispoartikelSNrBelegtplan" = 1065,
        "Disposition_Wartungshistorie" = 1070,
        "Disposition_Renditeliste" = 1080,
        "Disposition_NeuerVorgangDispo" = 1085,
        "Disposition_NeuerArtikelDispo" = 1095,
        "Mailmanager_VertraulicheMails" = 2,
        "Mailmanager_Serienbrief" = 19,
        "Mailmanager_VertraulicheE_Mails" = 1101,
        "Mailmanager_GlobalberechtigungfueralleE_Mails" = 1102,
        "Mailmanager_Geloeschtesichtbar" = 1103,
        "Mailmanager_AlleMailsMailadresseninKontaktenundGespraechsverlauf" = 1104,
        "Mailmanager_Mailliste" = 1110,
        "Mailmanager_Mailloeschen" = 1111,
        "Mailmanager_NeueMailundEntwuerfe" = 1120,
        "Mailmanager_Newsletter" = 1125,
        "Mailmanager_Zusammenhaenge" = 1130,
        "Mailmanager_Zusammenhaengeloeschen" = 1131,
        "Mailmanager_Zusammenhaengeeditieren" = 1132,
        "Mailmanager_Gespraechsverlauf" = 1140,
        "Mailmanager_MailAdressliste" = 1160,
        "Mailmanager_Mailadressdatenloeschen" = 1161,
        "Mailmanager_Maildateneditieren" = 1162,
        "Mailmanager_KontaktpersonausMail_Adresse" = 1164,
        "Mailmanager_NewsletterAbmeldung" = 1165,
        "Mailmanager_Mail_Adressetrash" = 1166,
        "Mailmanager_Mail_Adressereaktivieren" = 1167,
        "Mailmanager_KontaktausMail_Adressenoeffnen" = 1168,
        "Mailmanager_Textbausteineverwalten" = 1175,
        "Mailmanager_Textbausteineloeschen" = 1176,
        "Mailmanager_Mailanzeigen" = 1180,
        "Mailmanager_Dateiverwaltung" = 1190,
        "Mailmanager_Anhangloeschen" = 1191,
        "Mailmanager_Dateihochladen" = 1192,
        "Mailmanager_Dateiendirektherunterladen" = 1193,
        "Mailmanager_DMS_AlleNetzwerk_Verzeichnisse" = 1194,
        "Mailmanager_DMS_vertraulichsetzen" = 1195,
        "Mailmanager_DMS_Verlinkung" = 1196,
        "Mailmanager_Briefliste" = 1310,
        "Mailmanager_NeuerBrief" = 1320,
        "Service_AlleRMAs" = 495,
        "Service_RMALoeschen" = 496,
        "Service_NeueRMA" = 1205,
        "Service_Auftrag_Serviceauftragerstellen" = 1511,
        "Service_Auftrag_Anlagenliste" = 1520,
        "Service_Auftrag_Anlageanlegen" = 1521,
        "Service_Auftrag_PeripherieListe" = 1540,
        "Service_Auftrag_AnlassListe" = 1550,
        "Service_Auftrag_UrsacheListe" = 1560,
        "Gastro_Gastro" = 1600,
        "Procurement" = 99_002,
        "Subscription" = 99_003
    }
    export type ExtendedDateTime = string | Date;
}
export namespace PHPW.Api.Bodies {
    export interface StatusSuccess extends PHPW.Api.Lib.DefaultBody {
        status: string;
    }
    export type Model = PHPW.Api.Lib.DefaultBody;
}
export namespace PHPW.Api.Lib {
    export type DefaultBody = object;
    export type DefaultRequestArguments = object;
    export type DefaultRequestParameters = object;
}
export namespace PHPW.Api.Lib.Common {
    export enum StornoPreviousType {
        "No" = 0,
        "Yes" = 1,
        "YesNewIteration" = 2
    }
    export interface ArgumentName extends PHPW.Api.Lib.DefaultRequestArguments {
        name: string;
    }
    export interface ArgumentsArticleIdAndCategoryId extends PHPW.Api.Lib.DefaultRequestArguments {
        articleId: number;
        categoryId: number;
    }
    export interface ArgumentsHash extends PHPW.Api.Lib.DefaultRequestArguments {
        hash: string;
    }
    export interface ArgumentsId extends PHPW.Api.Lib.DefaultRequestArguments {
        id: number;
    }
    export interface ArgumentsIdAndCid extends PHPW.Api.Lib.DefaultRequestArguments {
        id: number;
        cid: number;
    }
    export interface ArgumentsIdAndLangid extends PHPW.Api.Lib.DefaultRequestArguments {
        id: number;
        lang: PHPW.Entities.Enum.ArticleLanguage;
    }
    export interface BodyComment extends PHPW.Api.Bodies.Model {
        comment: string | undefined;
    }
    export interface BodyDelete extends PHPW.Api.Bodies.Model {
        reason: string;
    }
    export interface BodyIds extends PHPW.Api.Bodies.Model {
        ids: number[];
    }
    export interface DateRange {
        from: string;
        till: string;
    }
    export interface ParamsArticleActionStock extends PHPW.Api.Lib.DefaultRequestParameters {
        placeIds: number[];
        typeIds: number[];
    }
    export interface ParamsArticleBuyingprice extends PHPW.Api.Lib.DefaultRequestParameters {
        amount: number;
        contactId: number;
        date: string;
        ignoreDate: boolean;
    }
    export interface ParamsArticleCategoryArticles extends PHPW.Api.Lib.DefaultRequestParameters {
        page: number;
    }
    export interface ParamsArticleSellingprice extends PHPW.Api.Lib.DefaultRequestParameters {
        amount: number;
        contactId: number;
        group: string;
        date: string;
    }
    export interface ParamsArticleSerials extends PHPW.Api.Lib.DefaultRequestParameters {
        extraSerialIds: number[];
        extraSerials: string[];
        extra: {onlyAvailable?: string; onlyDisposition?: string; from?: string; till?: string};
    }
    export interface ParamsDataNormExport extends PHPW.Api.Lib.DefaultRequestParameters {
        useAsId: string;
        langId: number;
        contactId: number;
    }
    export interface ParamsDate extends PHPW.Api.Lib.DefaultRequestParameters {
        date: PHPW.ExtendedDateTime;
    }
    export interface ParamsDateRangeExtra extends PHPW.Api.Lib.DefaultRequestParameters {
        extra: PHPW.Api.Lib.Common.DateRange;
    }
    export interface ParamsDelete extends PHPW.Api.Lib.DefaultRequestParameters {
        delete: boolean;
        note?: string | undefined;
    }
    export interface ParamsId extends PHPW.Api.Lib.DefaultRequestParameters {
        id?: number | undefined;
    }
    export interface ParamsIds extends PHPW.Api.Lib.DefaultRequestParameters {
        ids: number[];
    }
    export interface ParamsInventurActionAdjustAmount extends PHPW.Api.Lib.DefaultRequestParameters {
        id: number;
        extraComment: string | undefined;
    }
    export interface ParamsKasseEAN extends PHPW.Api.Lib.DefaultRequestParameters {
        ean: string;
    }
    export interface ParamsName extends PHPW.Api.Lib.DefaultRequestParameters {
        name: string;
    }
    export interface ParamsProcessCreate extends PHPW.Api.Lib.DefaultRequestParameters {
        ignoreWarning?: boolean | undefined;
        stornoPrevious?: PHPW.Api.Lib.Common.StornoPreviousType | undefined;
        noDocument?: boolean | undefined;
    }
    export interface ParamsRadio extends PHPW.Api.Lib.DefaultRequestParameters {
        id: number;
        radio?: number | undefined;
    }
    export interface ParamsSilent extends PHPW.Api.Lib.DefaultRequestParameters {
        silent: boolean;
    }
    export type UploadedFile = Blob;
}
export namespace PHPW.Api.Responses {
    export interface ActionResponse extends PHPW.Api.Responses.DefaultResponse {
        success: boolean;
    }
    export interface DefaultResponse extends PHPW.Api.Lib.DefaultBody {
        exception?: string | undefined;
        exceptionMessage?: string | undefined;
    }
    export interface FileSaveResponse extends PHPW.Api.Responses.DefaultResponse {
        newId: string | undefined;
        oldId: string | undefined;
    }
    export interface ResultActionEvent extends PHPW.Api.Lib.DefaultBody {
        result: string;
        data: string | number;
        action: string | undefined;
    }
    export interface SaveResponse extends PHPW.Api.Responses.DefaultResponse {
        newId?: number | undefined;
        oldId?: number | undefined;
    }
    export type DeleteResponse = PHPW.Api.Responses.DefaultResponse;
}
export namespace PHPW.Api.Routes.ComboboxModel {
    export interface Article {
        id?: number | undefined;
        text: string;
        number?: string | undefined;
        type?: PHPW.Api.Routes.Lib.Article.Lib.EArticleType | undefined;
        digits?: number | undefined;
        barcode?: string | undefined;
    }
    export interface ArticleBrand {
        id?: number | undefined;
        text: string;
    }
    export interface ArticleCategory {
        id?: number | undefined;
        text: string;
    }
    export interface ArticleCharge {
        id?: number | undefined;
        text: string;
        amount?: number | undefined;
    }
    export interface ArticleSellingPriceGroupName {
        id?: number | undefined;
        text: string;
    }
    export interface ArticleSerial {
        id?: number | undefined;
        text: string;
    }
    export interface Aufgabe {
        id?: number | undefined;
        text: string;
        done?: boolean | undefined;
        project?: number | undefined;
        type?: number | undefined;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
    }
    export interface Contact {
        id?: number | undefined;
        text: string;
        debitor?: number | undefined;
        kreditor?: number | undefined;
        firma1?: string | undefined;
        ort?: string | undefined;
        plz?: string | undefined;
        preisliste?: PHPW.Api.Routes.ComboboxModel.ArticleSellingPriceGroupName | undefined;
        debitorPaymentId?: number | undefined;
        kreditorPaymentId?: number | undefined;
        ustId?: string | undefined;
        kreditorPaymentDays?: number | undefined;
        debitorPaymentDays?: number | undefined;
        bank?: PHPW.Api.Routes.Lib.Contacts.Model.Bank | undefined;
    }
    export interface ContactAddress {
        id?: number | undefined;
        text: string;
    }
    export interface ContactFlag {
        id?: number | undefined;
        text: string;
        extendedText?: string | undefined;
    }
    export interface ContactLinkReason {
        text: string;
    }
    export interface ContactPerson {
        id?: number | undefined;
        text: string;
        vorname?: string | undefined;
        name?: string | undefined;
        position?: string | undefined;
    }
    export interface Country {
        id?: number | undefined;
        text: string;
        extendedText?: string | undefined;
    }
    export interface KampagneResultType {
        id?: number | undefined;
        name: string;
        text: string;
    }
    export interface Konto {
        name: string;
        id: number;
        text: string;
    }
    export interface LagerPlatz {
        id?: number | undefined;
        text: string;
        type?: PHPW.Entities.Enum.LagerPlatzTyp;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: number | undefined;
    }
    export interface LagerPlatzBestandType {
        category?: PHPW.Entities.Enum.StockAmountTypeCategory;
        intern?: boolean;
        extern?: boolean;
        available?: boolean;
        disposition?: boolean;
        id?: number | undefined;
        text: string;
    }
    export interface MailRelation {
        id?: number | undefined;
        text: string;
        expireAttachments?: number | undefined;
        expireText?: number | undefined;
    }
    export interface ParentProcess {
        id?: number | undefined;
        text: string;
        specList?: PHPW.Entities.Enum.ProcessCategory | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        typeId?: number | undefined;
    }
    export interface Permission {
        id?: number | undefined;
        text: string;
        number?: string | undefined;
        type?: number | undefined;
        critical?: boolean | undefined;
    }
    export interface Process {
        id?: number | undefined;
        text: string;
        documentNumber?: string | undefined;
        commission?: string | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        specList?: PHPW.Entities.Enum.ProcessCategory | undefined;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
    }
    export interface ProtocolJob {
        id?: number | undefined;
        text: string;
        extendedText?: string | undefined;
    }
    export interface ProtocolProject {
        id?: number | undefined;
        text: string;
        extendedText?: string | undefined;
    }
    export interface ProtocolState {
        id?: number | undefined;
        text: string;
        extendedText?: string | undefined;
    }
    export interface TextBlockTag {
        id?: number | undefined;
        text: string;
    }
    export interface Unit {
        id?: number | undefined;
        text: string;
        long?: string | undefined;
    }
    export interface User {
        id?: number | undefined;
        text: string;
    }
    export interface Vehicle {
        id?: number | undefined;
        text: string;
    }
    export interface Warengruppe {
        id?: number | undefined;
        text: string;
    }
    export interface ZolltarifNummer {
        ztn: string;
        bezeichnung: string;
        articleId: number;
        usage: number;
    }
}
export namespace PHPW.Api.Routes.Lib.ARB.Model {
    export interface ARBedit extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        outstanding?: number | undefined;
        dueDate?: PHPW.ExtendedDateTime | undefined;
        paymentId?: number | undefined;
        number: string;
        contactId?: number | undefined;
        kontoinhaber?: string | undefined;
        iban?: string | undefined;
        bic?: string | undefined;
        bank?: string | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Account {
    export interface GetAccountParams extends PHPW.Api.Lib.DefaultRequestParameters {
        year: number;
    }
    export interface PostAccountParams extends PHPW.Api.Lib.DefaultRequestParameters {
        id: number;
        year: number;
    }
}
export namespace PHPW.Api.Routes.Lib.Account.Model {
    export interface DeleteIds extends PHPW.Api.Bodies.Model {
        ids: number[];
    }
    export interface GetAccountSearchParams extends PHPW.Api.Lib.DefaultRequestParameters {
        filters: {[key: string]: string};
        sort?: Array<[string, "ASC" | "DESC"]> | undefined;
        rowCount?: number;
        offset: number;
        special?: {[key: string]: boolean | number | string | Date | undefined | number[]};
    }
    export interface GetAccountSearchParamsSpecial {
        show_booked: boolean;
        show_customs: boolean;
        year: number;
    }
    export interface Konto extends PHPW.Api.Bodies.Model {
        number: number;
        year: number;
        name?: string | undefined;
        origName?: string | undefined;
        kategorie?: string | undefined;
        origKategorie?: string | undefined;
        unterart?: string | undefined;
        origUnterart?: string | undefined;
        zusatzfunktion?: string | undefined;
        origZusatzfunktion?: string | undefined;
        funktionstyp?: string | undefined;
        origFunktionstyp?: string | undefined;
        funktion?: string | undefined;
        origFunktion?: string | undefined;
    }
    export interface PostAccountIdActionUpdateYearParams extends PHPW.Api.Lib.DefaultRequestParameters {
        sourceYear: number;
        targetYear: number;
    }
    export interface SetStateIds extends PHPW.Api.Bodies.Model {
        ids: number[];
        manuallySet: boolean;
        statusId: PHPW.Entities.Enum.MailStatus;
    }
}
export namespace PHPW.Api.Routes.Lib.Article.Lib {
    export enum EArticleType {
        "PIECE" = 0,
        "SERIAL" = 1,
        "BATCH" = 2,
        "COLLECTION" = 3
    }
}
export namespace PHPW.Api.Routes.Lib.Article.Model {
    export interface AccessoryLink extends PHPW.Api.Bodies.Model {
        child: PHPW.Api.Routes.ComboboxModel.Article;
    }
    export interface AlternativeLink extends PHPW.Api.Bodies.Model {
        child: PHPW.Api.Routes.ComboboxModel.Article;
    }
    export interface ArgumentsVersion extends PHPW.Api.Lib.DefaultRequestArguments {
        version: string;
    }
    export interface Article extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUser?: number | undefined;
        updateDate?: PHPW.ExtendedDateTime | undefined;
        updateUser?: number | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: number | undefined;
        lastArrival?: PHPW.ExtendedDateTime | undefined;
        importListe?: string | undefined;
        externeId?: string | undefined;
        barcode?: string | undefined;
        lagerware: boolean;
        bestand: number;
        dispoBestand: number;
        dispoBestandVermietet: number;
        meldebestand?: number | undefined;
        vorbestellt?: number | undefined;
        liefersperre: boolean;
        hersteller?: PHPW.Api.Routes.ComboboxModel.ArticleBrand | undefined;
        artikelnummer: string;
        internInfo?: string | undefined;
        popup?: string | undefined;
        gewicht?: number | undefined;
        length?: number | undefined;
        width?: number | undefined;
        height?: number | undefined;
        storageConditions: string[];
        mwst?: number | undefined;
        buchungsKonto?: number | undefined;
        einheit?: number | undefined;
        vpe?: number | undefined;
        vpeSize?: number | undefined;
        einkaufspreis: number;
        shoppreis?: number | undefined;
        haendlerBestellnummer?: string | undefined;
        warengruppe?: string | undefined;
        uvp: number;
        snPflege: PHPW.Api.Routes.Lib.Article.Lib.EArticleType;
        datei?: string | undefined;
        extra1?: string | undefined;
        extra2?: string | undefined;
        extra3?: string | undefined;
        extra4?: string | undefined;
        extra5?: string | undefined;
        skontierbar: boolean;
        originCountry?: number | undefined;
        zolltarifnr?: string | undefined;
        lohnminuten?: number | undefined;
        calculatedLohnminuten?: number | undefined;
        articleSetType: PHPW.Entities.Enum.ArticleSetType;
        datenblatt?: string | undefined;
        lagerOrt?: string | undefined;
        shops: PHPW.Api.Routes.Lib.Article.Model.ArticleShop[];
        kategorie?: string | undefined;
        datanorm?: PHPW.Api.Routes.Lib.Article.Model.Datanorm | undefined;
        categories: PHPW.Api.Routes.ComboboxModel.ArticleCategory[];
        werbetextKurz?: string | undefined;
        werbetextLang?: string | undefined;
        dispo?: PHPW.Api.Routes.Lib.Article.Model.Dispo | undefined;
        digits?: number | undefined;
        prices: PHPW.Price.Model.PriceGroup[];
        priceContacts: PHPW.Price.Model.PriceContact[];
        einkaufspreise: PHPW.Api.Routes.Lib.Article.Model.Einkaufspreis[];
        extraCost?: number | undefined;
        rabattierbar: boolean;
        images: PHPW.Api.Routes.Lib.Article.Model.ArticleImage[];
        variantParent?: PHPW.Api.Routes.ComboboxModel.Article | undefined;
        variantAttributes: string[];
        hasActiveInventoryStop: boolean;
        languages: PHPW.Api.Routes.Lib.Article.Model.ArticleLanguages;
    }
    export interface ArticleCategory extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        name: string;
        parentId?: number | undefined;
        description: string;
    }
    export interface ArticleCharge extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        articleId: number;
        nummer: string;
        name?: string | undefined;
        bestand?: number | undefined;
        expireDate?: PHPW.ExtendedDateTime | undefined;
        info?: string | undefined;
        dispoBatch?: PHPW.Api.Routes.Lib.Article.Model.DispoBatch | undefined;
        stockPlaces: PHPW.Api.Routes.Lib.Lager.Model.LagerPlatzBestand[];
    }
    export interface ArticleImage {
        hash: string;
        filename: string;
        insertDate: PHPW.ExtendedDateTime | undefined;
        deleted: boolean;
    }
    export interface ArticleLanguage extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        language: PHPW.Entities.Enum.ArticleLanguage;
        artikelbezeichnung?: string | undefined;
        artikeltext?: string | undefined;
        artikellangtext?: string | undefined;
    }
    export interface ArticleLanguages {
        de: PHPW.Api.Routes.Lib.Article.Model.ArticleLanguage;
        en: PHPW.Api.Routes.Lib.Article.Model.ArticleLanguage;
    }
    export interface ArticleSerial extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        articleId?: number | undefined;
        serialNumber: string;
        receiving?: PHPW.ExtendedDateTime | undefined;
        comment?: string | undefined;
        internalInfo?: string | undefined;
        dispoSerial?: PHPW.Api.Routes.Lib.Article.Model.DispoSerial | undefined;
        stockPlaces: PHPW.Api.Routes.Lib.Lager.Model.LagerPlatzBestand[];
    }
    export interface ArticleShop extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        shopId: number;
        shopRef: string;
    }
    export interface ArticleShopUpload extends PHPW.Api.Bodies.Model {
        shopId: number;
        articleId: number;
    }
    export interface CheckArticlenumberBody extends PHPW.Api.Bodies.Model {
        articleNumber: string;
        articleId?: number | undefined;
    }
    export interface CostPriceCorrection extends PHPW.Api.Bodies.Model {
        ids: number[];
        priceType: number;
        name?: string | undefined;
        changeAll?: boolean | undefined;
        costPriceAction?: number | undefined;
        isRef: boolean;
        value?: number;
        isMoney: boolean;
        salePriceAction?: number | undefined;
        priceDate: PHPW.ExtendedDateTime;
    }
    export interface CostPriceCorrectionInfo {
        articleId?: number | undefined;
        articleNumber: string;
        noActiveCostPrice: boolean;
        hasEmptyCostPrice: boolean;
        hasEmptyPriceCorrection: boolean;
        noActiveSalePrice: boolean;
        hasEmptySalePrice: boolean;
    }
    export interface CostPriceCorrectionResponse extends PHPW.Api.Responses.ActionResponse {
        infos: PHPW.Api.Routes.Lib.Article.Model.CostPriceCorrectionInfo[];
    }
    export interface CountData extends PHPW.Api.Bodies.Model {
        count?: number;
        data: PHPW.Api.Routes.Lib.Kasse.Model.KasseArticle[];
    }
    export interface Datanorm {
        id?: number | undefined;
        shortDescription1?: string | undefined;
        shortDescription2?: string | undefined;
        longTextblock?: string | undefined;
    }
    export interface Dispo {
        id?: number | undefined;
        artikelnummer?: string | undefined;
        artikelbezeichnung?: string | undefined;
        verpackungsumfang?: string | undefined;
        artikeltext?: string | undefined;
        basePrice?: number | undefined;
        priceMode: PHPW.Entities.Enum.PriceMode;
        prices: PHPW.Api.Routes.Lib.Article.Model.DispoPrice[];
        globalPrices: PHPW.Api.Routes.Lib.Article.Model.DispoPriceGlobal[];
    }
    export interface DispoBatch {
        id?: number | undefined;
        inventarNr?: string | undefined;
        ean?: string | undefined;
        ekAnschaffung?: number | undefined;
        info?: string | undefined;
        internalInfo?: string | undefined;
        operationTime?: number | undefined;
        timeSpanAssignments: Array<{from: string; till: string; dpa_id: number; pp_id: number; amount: number}>;
    }
    export interface DispoPrice {
        id?: number | undefined;
        abTage: number;
        proTag: number;
        operator: string;
        deleted: boolean;
    }
    export interface DispoPriceGlobal {
        id?: number | undefined;
        abTage: number;
        percent: number;
        operator: string;
        deleted: boolean;
    }
    export interface DispoSerial {
        id?: number | undefined;
        inventarNr?: string | undefined;
        mietcode?: string | undefined;
        ekAnschaffung?: number | undefined;
        info?: string | undefined;
        internalInfo?: string | undefined;
        operationTime?: number | undefined;
        timeSpanAssignments: Array<{from: string; till: string; ppg_id: number}>;
    }
    export interface DmsToArticle extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        public: boolean;
    }
    export interface Einkaufspreis {
        id?: number | undefined;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        price: number;
        prcnt1: number[];
        name: string;
        minAmount: number;
        deleted: boolean;
        start?: PHPW.ExtendedDateTime | undefined;
        stop?: PHPW.ExtendedDateTime | undefined;
    }
    export interface ExportRun extends PHPW.Api.Bodies.Model {
        ids: number[];
    }
    export interface GetArticleActionStockResponse extends PHPW.Api.Bodies.Model {
        pieces: PHPW.Api.Routes.Lib.Lager.Model.LagerPlatzBestand[];
        serials: PHPW.Api.Routes.Lib.Lager.Model.LagerPlatzBestand[];
        batches: PHPW.Api.Routes.Lib.Lager.Model.LagerPlatzBestand[];
    }
    export interface GetArticleIdStockResponse extends PHPW.Api.Bodies.Model {
        type_id: number;
        name: string;
        category: string;
        available: boolean;
        disposition: boolean;
        typ: number;
        bestand: number;
    }
    export interface ImportDryrun extends PHPW.Api.Bodies.Model {
        type?: string | undefined;
        settings: PHPW.Data.Io.Import.Article.Model.ImportSettings;
    }
    export interface ParamsImportExample extends PHPW.Api.Lib.DefaultRequestParameters {
        name: string;
    }
    export interface PostArticleActionStorageCorrectionResponse extends PHPW.Api.Responses.DefaultResponse {
        success: boolean;
        errorCollection: string[];
    }
    export interface PostArticleForInventurResponse extends PHPW.Api.Responses.DefaultResponse {
        openIds: number[];
        savedIdCount: number;
        findProblems?: number[] | undefined;
        persistProblems?: number[] | undefined;
    }
    export interface PostInventurArticleSolveBody extends PHPW.Api.Bodies.Model {
        info: string;
    }
    export interface SetArticleLink extends PHPW.Api.Bodies.Model {
        child: PHPW.Api.Routes.ComboboxModel.Article;
        amount: number;
    }
    export interface ShopUpload extends PHPW.Api.Bodies.Model {
        shopId: number;
        categoryId: number;
    }
    export interface Wareneingang extends PHPW.Api.Bodies.Model {
        targetPlace?: PHPW.Api.Routes.ComboboxModel.LagerPlatz | undefined;
        noOrder: boolean;
        lieferant?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        process?: PHPW.Api.Routes.ComboboxModel.Process | undefined;
        lieferscheinNr?: string | undefined;
        kommission?: string | undefined;
        kommentar?: string | undefined;
        amount?: number | undefined;
        serials: PHPW.Api.Routes.Lib.Article.Model.WareneingangSerialOrCharge[];
    }
    export interface WareneingangSerialOrCharge {
        amount?: number | undefined;
        snr?: string | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Article.Params {
    export interface ParamsArticleCharges extends PHPW.Api.Lib.DefaultRequestParameters {
        extraBatchIds: string[];
        extra: string[];
    }
}
export namespace PHPW.Api.Routes.Lib.Aufgabe.Model {
    export enum EAufgabeLinkTypes {
        "Process" = "Process",
        "BookingIncomingBill" = "BookingIncomingBill",
        "ParentAufgabe" = "ParentAufgabe",
        "Aufgabe" = "Aufgabe",
        "Mail" = "Mail",
        "Contact" = "Contact",
        "ContactPerson" = "ContactPerson",
        "Artikel" = "Artikel",
        "Article" = "Article",
        "RMA" = "RMA",
        "Project" = "Project",
        "Termin" = "Termin"
    }
    export enum SubtaskType {
        "Default" = "default",
        "Task" = "aufgabe"
    }
    export interface ArgumentsAufgabeArticlePosition extends PHPW.Api.Lib.DefaultRequestArguments {
        id: number;
        article_id: number;
    }
    export interface Aufgabe extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        hashId?: string | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUser?: number | undefined;
        updateDate?: PHPW.ExtendedDateTime | undefined;
        updateUser?: number | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: number | undefined;
        shortname?: string | undefined;
        summary?: string | undefined;
        email?: string | undefined;
        deadline?: PHPW.ExtendedDateTime | undefined;
        tags?: undefined | string[];
        private: boolean;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        creatorId?: number | undefined;
        timeNeeded?: number | undefined;
        typeId?: number | undefined;
        stateId?: number | undefined;
        priorityId?: number | undefined;
        categoryId?: number | undefined;
        guid?: string | undefined;
        links: PHPW.Api.Routes.Lib.Aufgabe.Model.AufgabeLink[];
        userData: PHPW.Api.Routes.Lib.Aufgabe.Model.AufgabeUserLink[];
        fileCount: number;
        timeCount: number;
        rma?: PHPW.Api.Routes.Lib.Rma.Model.Rma | undefined;
        subtasks: PHPW.Api.Routes.Lib.Aufgabe.Model.Subtask[];
    }
    export interface AufgabeActionToPrivate extends PHPW.Api.Bodies.Model {
        id?: number;
    }
    export interface AufgabeCcsComment {
        type: PHPW.Entities.Enum.AufgabeCommentType.Ccs;
        id?: number | undefined;
        time: PHPW.ExtendedDateTime;
        text: string;
        name: string;
        tag: PHPW.Entities.Enum.AufgabeCommentTag;
        cId?: number | undefined;
    }
    export interface AufgabeChange {
        type: PHPW.Entities.Enum.AufgabeCommentType.Auto;
        id?: number | undefined;
        time: PHPW.ExtendedDateTime;
        creator?: number | undefined;
        tag: PHPW.Entities.Enum.AufgabeCommentTag;
        autoComment: undefined | PHPW.Api.Routes.Lib.Aufgabe.Model.AutoCommmentStruct[];
        autoCommentText: string | undefined;
    }
    export interface AufgabeComment extends PHPW.Api.Bodies.Model {
        type: PHPW.Entities.Enum.AufgabeCommentType.Comment;
        id?: number | undefined;
        time: PHPW.ExtendedDateTime;
        text: string;
        creatorId?: number | undefined;
        toComment?: number | undefined;
        tag: PHPW.Entities.Enum.AufgabeCommentTag;
        toUser: PHPW.Api.Routes.Lib.Aufgabe.Model.AufgabeCommentToUser[];
    }
    export interface AufgabeCommentId extends PHPW.Api.Bodies.Model {
        type?: number | undefined;
        read?: boolean | undefined;
        commentTag?: number | undefined;
    }
    export interface AufgabeCommentToUser {
        userId: number;
        typeId: number;
        closed?: PHPW.ExtendedDateTime | undefined;
        userKuerzel?: string | undefined;
    }
    export interface AufgabeIdComment extends PHPW.Api.Bodies.Model {
        type: string;
        aufgabeComment?: PHPW.Api.Routes.Lib.Aufgabe.Model.AufgabeComment | undefined;
        aufgabeCcsComment?: PHPW.Api.Routes.Lib.Aufgabe.Model.AufgabeCcsComment | undefined;
    }
    export interface AufgabeLink extends PHPW.Api.Bodies.Model {
        destinationId: number;
        destinationType: PHPW.Api.Routes.Lib.Aufgabe.Model.EAufgabeLinkTypes;
        description?: string | undefined;
        extendedDescription?: string | undefined;
        new?: boolean;
        deleted?: boolean;
    }
    export interface AufgabePosition extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        aufgabe?: PHPW.Api.Routes.ComboboxModel.Aufgabe | undefined;
        article?: PHPW.Api.Routes.ComboboxModel.Article | undefined;
        serials: PHPW.Api.Routes.ComboboxModel.ArticleSerial[];
        amount: number;
        charges: PHPW.Api.Routes.ComboboxModel.ArticleCharge[];
        addToStock: boolean;
        stockPlace?: PHPW.Api.Routes.ComboboxModel.LagerPlatz | undefined;
        stockType?: PHPW.Api.Routes.ComboboxModel.LagerPlatzBestandType | undefined;
    }
    export interface AufgabePriority extends PHPW.Api.Bodies.Model {
        id: number;
        text: string;
        color: string;
        orderBy: number;
    }
    export interface AufgabeTemplateToAufgabeV1 {
        shortname?: string | undefined;
        summary?: string | undefined;
        email?: string | undefined;
        deadline?: PHPW.ExtendedDateTime | undefined;
        tags?: string[];
        private: boolean;
        creatorId?: number | undefined;
        timeNeeded?: number | undefined;
        typeId?: number | undefined;
        stateId?: number | undefined;
        priorityId?: number | undefined;
        categoryId?: number | undefined;
        userData: PHPW.Api.Routes.Lib.Aufgabe.Model.AufgabeUserLink[];
        subtasks: PHPW.Api.Routes.Lib.Aufgabe.Model.Subtask[];
    }
    export interface AufgabeToUser extends PHPW.Api.Bodies.Model {
        user_id: number | undefined;
        scheduledDate: PHPW.ExtendedDateTime | undefined;
    }
    export interface AufgabeUserLink {
        userId: number;
        scheduledDate?: PHPW.ExtendedDateTime | undefined;
        new: boolean;
        deleted: boolean;
        changed: boolean;
        sticky: boolean;
        admin: boolean;
    }
    export interface AutoCommmentStruct {
        type: "user" | "status" | "priority" | "tag" | "link" | "category";
        action: "add" | "change" | "remove" | "readerChange" | "stickyChange";
        scheduledDate?: string | undefined;
        id?: string | number | undefined;
        link?: string | undefined;
        linkData?: PHPW.Aufgabe.Model.AufgabeLink | undefined;
        name?: string | undefined;
        text?: string | undefined;
        readerOnly: boolean;
        sticky: boolean;
    }
    export interface Microtask extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        done?: boolean | undefined;
        summary?: string | undefined;
        notes?: string | undefined;
        insertUser?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        updateUser?: number | undefined;
        updateDate?: PHPW.ExtendedDateTime | undefined;
    }
    export interface ParamsAufgabeActionSetRadios extends PHPW.Api.Lib.DefaultRequestParameters {
        id: number;
        radio: number | undefined;
    }
    export interface ParamsAufgabeLink extends PHPW.Api.Lib.DefaultRequestParameters {
        destinationType: PHPW.Api.Routes.Lib.Aufgabe.Model.EAufgabeLinkTypes;
        destinationId: number;
    }
    export interface Subtask {
        type: PHPW.Api.Routes.Lib.Aufgabe.Model.SubtaskType;
        aufgabe: PHPW.Api.Routes.Lib.Aufgabe.Model.SubtaskAufgabe | undefined;
        microtask: PHPW.Api.Routes.Lib.Aufgabe.Model.Microtask | undefined;
        orderIndex?: number | undefined;
        id: number | undefined;
    }
    export interface SubtaskAufgabe extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUser?: number | undefined;
        updateDate?: PHPW.ExtendedDateTime | undefined;
        updateUser?: number | undefined;
        shortname?: string | undefined;
        summary?: string | undefined;
        deadline?: PHPW.ExtendedDateTime | undefined;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        timeNeeded?: number | undefined;
        stateId?: number | undefined;
        typeId?: number | undefined;
        priorityId?: number | undefined;
        categoryId?: number | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Bank.Model {
    export interface Bank extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        active: boolean;
        short: string;
        orderBy: number;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUser?: number | undefined;
        iban: string;
        bic: string;
        name: string;
        inhaber: string;
        buha_konto?: number | undefined;
        ar_document_circuit?: number | undefined;
        er_document_circuit?: number | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.BankStatementEntry.Model {
    export interface BankStatement extends PHPW.Api.Bodies.Model {
        identification: string;
        electronicSequenceNumber?: number | undefined;
        legalSequenceNumber?: number | undefined;
        creationDateTime?: PHPW.ExtendedDateTime | undefined;
        type?: string | undefined;
        currency?: string | undefined;
    }
    export interface BankStatementEntry extends PHPW.Api.Bodies.Model {
        id: number;
        bankStatement: PHPW.Api.Routes.Lib.BankStatementEntry.Model.BankStatement;
        entryReference?: string;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        amount?: number;
        bookingDate?: PHPW.ExtendedDateTime;
        valueDate?: PHPW.ExtendedDateTime;
        endToEndIdentification?: string | undefined;
        partyName?: string | undefined;
        partyIban?: string | undefined;
        partyBic?: string | undefined;
        remittanceInformation?: string | undefined;
        typeDomain?: PHPW.Camt.Enum.TypeDomain;
        typeFamily?: PHPW.Camt.Enum.TypeFamily;
        typeSubFamily?: PHPW.Camt.Enum.TypeSubFamily;
        relevantIncomingInvoices: PHPW.Camt.DTO.ConnectRow[];
        relevantOutgoingInvoices: PHPW.Camt.DTO.ConnectRow[];
        relevantBookings: PHPW.Camt.DTO.ConnectRow[];
        bookings: PHPW.Camt.DTO.ConnectRow[];
    }
}
export namespace PHPW.Api.Routes.Lib.Board.Model {
    export interface Board extends PHPW.Api.Bodies.Model {
        lanes: PHPW.Api.Routes.Lib.Board.Model.Lane[];
    }
    export interface BoardIdMoveElement extends PHPW.Api.Bodies.Model {
        aufgabeId: number;
        destLaneId: number;
    }
    export interface BoardRequest extends PHPW.Api.Bodies.Model {
        typeId: number;
        filterString: string | undefined;
    }
    export interface Element extends PHPW.Api.Bodies.Model {
        id: number;
        shortname: string;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        aufgabePriority: number;
        scheduledDate?: PHPW.ExtendedDateTime | undefined;
        summary: string;
        mailCount: number;
        taskCount: number;
        fileCount: number;
    }
    export interface Lane extends PHPW.Api.Bodies.Model {
        id: number;
        name: string;
        color: string;
        elements: PHPW.Api.Routes.Lib.Board.Model.Element[];
    }
    export interface ParamsBoardId extends PHPW.Api.Lib.DefaultRequestParameters {
        filter: string;
        userIds: undefined | number[];
    }
}
export namespace PHPW.Api.Routes.Lib.Booking.Model {
    export interface AccountOverride {
        account: number;
        accountOverride: number;
    }
    export interface ArgumentsHide extends PHPW.Api.Lib.DefaultRequestArguments {
        hide: 0 | 1;
    }
    export interface ArgumentsSollHaben extends PHPW.Api.Lib.DefaultRequestArguments {
        soll: number;
        haben: number;
    }
    export interface Booking extends PHPW.Api.Bodies.Model {
        belegdatum: PHPW.ExtendedDateTime;
        sollHaben: PHPW.Api.Routes.Lib.Booking.Model.BookingElement[];
        belegnummerKreisId?: number | undefined;
        belegnummer?: string | undefined;
        beleginfo?: string | undefined;
        comment?: string | undefined;
        kostenstelle?: number | undefined;
        gesellschafter?: number | undefined;
        leistungsdatum?: PHPW.ExtendedDateTime | undefined;
        ustId?: string | undefined;
        erBooking?: PHPW.Api.Routes.Lib.Booking.Model.BookingIncoming | undefined;
        nummer?: string | undefined;
        arbNumber?: number | undefined;
        erbNumber?: number | undefined;
        euTaxFactor?: number | undefined;
        bankStatementEntries: number[];
        proformaPayment?: PHPW.Api.Routes.Lib.Booking.Model.ProformaPayment | undefined;
    }
    export interface BookingActionChangeCostcenter extends PHPW.Api.Bodies.Model {
        id: number;
        costcenterId: number;
    }
    export interface BookingActionChangeGesellschafter extends PHPW.Api.Bodies.Model {
        id: number;
        gesellschafterId: number;
    }
    export interface BookingActionErbDeleteexport extends PHPW.Api.Bodies.Model {
        ids: number[];
    }
    export interface BookingActionRebook extends PHPW.Api.Bodies.Model {
        id: number;
    }
    export interface BookingActionSetState extends PHPW.Api.Bodies.Model {
        comment: string;
        state: number;
    }
    export interface BookingActionToJournal extends PHPW.Api.Bodies.Model {
        id: number;
    }
    export interface BookingActionToPrivate extends PHPW.Api.Bodies.Model {
        id: number;
    }
    export interface BookingCostCenter extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        short?: string | undefined;
        name: string;
    }
    export interface BookingDocumentCircuit extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        short?: string | undefined;
        name: string;
    }
    export interface BookingElement {
        id?: number | undefined;
        text?: string | undefined;
        soll?: number | undefined;
        haben?: number | undefined;
        amount: number;
        taxkey: number | undefined;
        taxFactor?: number | undefined;
        taxkeyGroup?: number | undefined;
        amountSkonto?: number | undefined;
    }
    export interface BookingIncoming extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUser?: number | undefined;
        updateDate?: PHPW.ExtendedDateTime | undefined;
        updateUser?: number | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: number | undefined;
        lieferant?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        number?: string | undefined;
        date?: PHPW.ExtendedDateTime | undefined;
        kurztext?: string | undefined;
        amount?: number | undefined;
        tax?: number | undefined;
        amount_tax?: number | undefined;
        amount_netto?: number | undefined;
        matureDate?: PHPW.ExtendedDateTime | undefined;
        paymentId?: number | undefined;
        skonto?: number | undefined;
        skontoMatureDate?: PHPW.ExtendedDateTime | undefined;
        bank?: string | undefined;
        kontoinhaber?: string | undefined;
        iban?: string | undefined;
        bic?: string | undefined;
        tasks: PHPW.Api.Routes.ComboboxModel.Aufgabe[];
        files: PHPW.Api.Routes.Lib.Files.Model.File[];
        status: number | undefined;
        comment: string | undefined;
        positions: PHPW.Api.Routes.Lib.Booking.Model.BookingIncomingPosition[];
        bookingId: number | undefined;
    }
    export interface BookingIncomingPosition {
        id?: number | undefined;
        position: number;
        article?: PHPW.Api.Routes.ComboboxModel.Article | undefined;
        bezeichnung: string;
        amount: number;
        price: number;
        taxId?: number | undefined;
        lieferscheinNummer?: string | undefined;
        unitId?: number | undefined;
        country?: number | undefined;
        weight?: number | undefined;
        intraHandel?: PHPW.Api.Routes.Lib.Booking.Model.IntraHandel | undefined;
        intraHandelType: number;
        articleSerials?: undefined | PHPW.Api.Routes.ComboboxModel.ArticleSerial[];
    }
    export interface BookingPreview {
        soll: PHPW.Api.Routes.Lib.Booking.Model.BookingPreviewElement[];
        haben: PHPW.Api.Routes.Lib.Booking.Model.BookingPreviewElement[];
    }
    export interface BookingPreviewElement {
        account: number;
        amount: number;
        ustPos?: number | undefined;
    }
    export interface BookingSingle extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        amount?: number | undefined;
        soll?: number | undefined;
        haben?: number | undefined;
        ustpos?: number | undefined;
    }
    export interface ChangeKonto extends PHPW.Api.Bodies.Model {
        oldKonto: number;
        newKonto: number;
        ids: number[];
    }
    export interface ChangeLeistungsDatum extends PHPW.Api.Bodies.Model {
        selected_ids: number[];
        date: string;
        action: number;
    }
    export interface ChangeTaxDate extends PHPW.Api.Bodies.Model {
        selected_ids: number[];
        date: string;
        action: number;
    }
    export interface ChangeTaxKey extends PHPW.Api.Bodies.Model {
        selected_ids: number[];
        tax_key: string;
    }
    export interface DatevImportRun extends PHPW.Api.Bodies.Model {
        documentCircuit?: number | undefined;
        accountOverride: PHPW.Api.Routes.Lib.Booking.Model.AccountOverride[];
    }
    export interface FindRelated extends PHPW.Api.Bodies.Model {
        start: PHPW.ExtendedDateTime;
        ende: PHPW.ExtendedDateTime;
        min: PHPW.ExtendedDateTime;
        max: PHPW.ExtendedDateTime;
        srckto: string;
        action: number;
        get: string;
    }
    export interface FindRelatedResponse extends PHPW.Api.Responses.DefaultResponse {
        ids: number[];
    }
    export interface IntraHandel {
        id?: number | undefined;
        positionId: number;
        verkehrsRichtung: string;
        bezugsZeitraum: number;
        kaufArt: string;
        verkehrsZweig: string;
        versenderLand: string;
        bestimmungsRegion: string;
        bestimmungsLand: string;
        ursprungsLand: string;
        ursprungsRegion?: string | undefined;
        zollTarifNummer: string;
        warenBezeichnung: string;
        masse: number;
        besondereEinheit: string;
        rechnungsWert: number;
        statistischerWert: number;
        jahr: number;
    }
    export interface InvoiceNumber extends PHPW.Api.Bodies.Model {
        invoice: string;
    }
    export interface ParamsBookingActionDatevExport extends PHPW.Api.Lib.DefaultRequestParameters {
        ids: string[];
        timeframe: string;
        year: string | undefined;
        overrideLeistungsDatum: string | undefined;
        belegbilder: boolean;
        ignoreUstId: string | undefined;
        belegfeldOneIsRechnungsnummer: string | undefined;
    }
    export interface ParamsBookingActionInstatXmlExport extends PHPW.Api.Lib.DefaultRequestParameters {
        year: string;
        timeframe: string;
        materialNumber?: string | undefined;
        makeTest: boolean;
    }
    export interface ParamsBookingPreset extends PHPW.Api.Lib.DefaultRequestParameters {
        processId: number | undefined;
        incomingBillId: number | undefined;
        proformaPaymentId: number | undefined;
        bankStatementEntryId: number | undefined;
    }
    export interface ParamsBookingTemplate extends PHPW.Api.Lib.DefaultRequestParameters {
        id: number;
        name: string;
        nextDate: string;
    }
    export interface ParamsDeleteBookingFileLinkId extends PHPW.Api.Lib.DefaultRequestParameters {
        erbIds: number[];
    }
    export interface ParamsTaxkeySollHaben extends PHPW.Api.Lib.DefaultRequestParameters {
        date: PHPW.ExtendedDateTime;
        skonto: boolean;
        oss: boolean;
        tkGroup?: number;
    }
    export interface PostBookingActionConvertXlsxSepaBody extends PHPW.Api.Lib.DefaultBody {
        bankId: number;
    }
    export interface PostBookingActionVisualizeERechnungBody extends PHPW.Api.Lib.DefaultBody {
        hash?: string | undefined;
    }
    export interface PostBookingArbActionDeleteExport extends PHPW.Api.Bodies.Model {
        ids: number[];
    }
    export interface ProformaPayment extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        proforma?: PHPW.Api.Routes.ComboboxModel.Process | undefined;
        invoice?: PHPW.Api.Routes.ComboboxModel.Process[] | undefined;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        booking_id?: number | undefined;
        bankId?: number | undefined;
        date?: PHPW.ExtendedDateTime | undefined;
        amount?: number | undefined;
        comment?: string | undefined;
    }
    export interface ProformaPaymentComment extends PHPW.Api.Bodies.Model {
        id: number;
        comment: string;
    }
    export interface ProformaPaymentToBooking extends PHPW.Api.Bodies.Model {
        id: number;
        booking_ids: number[];
    }
    export interface ProformaPaymentToPaymentBooking extends PHPW.Api.Bodies.Model {
        id: number;
        booking_ids: number[];
    }
    export interface ReconcileProforma extends PHPW.Api.Bodies.Model {
        invoicePaymentBad: number[];
        invoicePaymentGood: number[];
        notConnected: number[];
        proforma: PHPW.Api.Routes.Lib.Booking.Model.ReconcileProformaElement[];
    }
    export interface ReconcileProformaElement extends PHPW.Api.Bodies.Model {
        proformaPaymentBad: number[];
        proformaPaymentGood: number[];
    }
    export interface ReconcileProformaSetting extends PHPW.Api.Bodies.Model {
        proformaVon: PHPW.ExtendedDateTime;
        proformaBis: PHPW.ExtendedDateTime;
        rechnungVon: PHPW.ExtendedDateTime;
        rechnungBis: PHPW.ExtendedDateTime;
    }
    export interface ResponseBookingPreview extends PHPW.Api.Responses.DefaultResponse {
        sets: PHPW.Api.Routes.Lib.Booking.Model.BookingPreview[];
    }
}
export namespace PHPW.Api.Routes.Lib.Calendar.Model {
    export interface CalendarObject extends PHPW.Api.Bodies.Model {
        id?: string | number | undefined;
        calendarData: string;
        uri?: string | undefined;
        calendarId?: number | undefined;
        lastModified?: PHPW.ExtendedDateTime | undefined;
        etag?: string | undefined;
        size?: number | undefined;
        componentType?: string | undefined;
        firstOccurence?: PHPW.ExtendedDateTime | undefined;
        lastOccurence?: PHPW.ExtendedDateTime | undefined;
        uid?: string | undefined;
        users: PHPW.Api.Routes.ComboboxModel.User[];
        aufgaben: PHPW.Api.Routes.ComboboxModel.Aufgabe[];
        contacts: PHPW.Api.Routes.ComboboxModel.Contact[];
    }
    export interface OverviewCalendar extends PHPW.Api.Bodies.Model {
        day0: PHPW.Api.Routes.Lib.Calendar.Model.OverviewDay;
        day1: PHPW.Api.Routes.Lib.Calendar.Model.OverviewDay;
        day2: PHPW.Api.Routes.Lib.Calendar.Model.OverviewDay;
        day3: PHPW.Api.Routes.Lib.Calendar.Model.OverviewDay;
        day4: PHPW.Api.Routes.Lib.Calendar.Model.OverviewDay;
        day5: PHPW.Api.Routes.Lib.Calendar.Model.OverviewDay;
        day6: PHPW.Api.Routes.Lib.Calendar.Model.OverviewDay;
    }
    export interface OverviewDay {
        date: PHPW.ExtendedDateTime;
        personal: PHPW.Api.Routes.Lib.Calendar.Model.OverviewEvent[];
        termine: PHPW.Api.Routes.Lib.Calendar.Model.OverviewEvent[];
        events: PHPW.Api.Routes.Lib.Calendar.Model.OverviewEvent[];
        aufgaben: PHPW.Api.Routes.Lib.Calendar.Model.OverviewEvent[];
        dispo: PHPW.Api.Routes.Lib.Calendar.Model.OverviewEvent[];
        vehicles: PHPW.Api.Routes.Lib.Calendar.Model.OverviewEvent[];
    }
    export interface OverviewEvent {
        name: string;
        color: string | undefined;
    }
    export interface ParamsGetCalendar extends PHPW.Api.Lib.DefaultRequestParameters {
        startDate: PHPW.ExtendedDateTime;
        endDate: PHPW.ExtendedDateTime;
        userId?: number | undefined;
        specialFilter?: PHPW.Api.Routes.Lib.Calendar.Model.ParamsGetCalendarSpecialFilter | undefined;
    }
    export interface ParamsGetCalendarSpecialFilter {
        public?: boolean | undefined;
        private?: boolean | undefined;
        attendance?: boolean | undefined;
        holidays?: boolean | undefined;
        staff?: boolean | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Chat.Model {
    export interface ActionReadRequest extends PHPW.Api.Bodies.Model {
        room: string;
        messageId: number;
    }
}
export namespace PHPW.Api.Routes.Lib.Config.Model {
    export interface ArgumentProfile extends PHPW.Api.Lib.DefaultRequestArguments {
        profile: string;
    }
    export interface Config extends PHPW.Api.Bodies.Model {
        Amount_Decimals: number;
        Company_Country: number;
        Company_Name: string;
        Default_Bank: number | undefined;
        List_Limit: number;
        isdemo: boolean;
        standardTemplate: string | undefined;
        wiki_path: string;
        ZE_Default_Bank_Account: number | undefined;
        ZA_Default_Bank_Account: number | undefined;
        ER_Text: string;
        Kassen_Konto: number | undefined;
        Interim: number;
        KreditorRange: string;
        DebitorRange: string;
        Booking_Calc_Basedate: string | undefined;
        VK1_Multiplier: number;
        VK2_Multiplier: number;
        VK3_Multiplier: number;
        Always_PriceGroupInfo: boolean;
        Default_Einheit: number | undefined;
        ExtendedPrices: boolean;
        DispositionShowTime: boolean;
        Angebot_Zeit: number;
        default_end_time: string;
        default_start_time: string;
        Default_Account: number | undefined;
        Default_Account_TaxFree: number | undefined;
        Default_Account_TaxFree_EG_Leistung: number | undefined;
        Default_Account_TaxFree_Drittland_Lieferung: number | undefined;
        Default_Account_TaxFree_Drittland_Leistung: number | undefined;
        Default_Account_TaxFree_Bauleistung: number | undefined;
        Default_Account_TaxFree_Seeschiffe: number | undefined;
        Default_Account_TaxFree_Dreiecksgeschaeft: number | undefined;
        Stock_Receipt_NoOrder: boolean;
        Kontenrahmen: string;
        Booking_Modus: string | undefined;
        MailFindIgnore: string | undefined;
    }
    export interface DeleteConfigParams extends PHPW.Api.Lib.DefaultRequestParameters {
        profile: string;
    }
    export interface ParamsProfile extends PHPW.Api.Lib.DefaultRequestParameters {
        profile: string;
    }
}
export namespace PHPW.Api.Routes.Lib.ContactPerson.Model {
    export interface ContactPerson extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        insertDate: PHPW.ExtendedDateTime | undefined;
        insertUser: number | undefined;
        updateDate: PHPW.ExtendedDateTime | undefined;
        updateUser: number | undefined;
        trashDate: PHPW.ExtendedDateTime | undefined;
        trashUser: number | undefined;
        name: string | undefined;
        vorname: string | undefined;
        anrede: string | undefined;
        geschlecht: string | undefined;
        geburtstag: PHPW.ExtendedDateTime | undefined;
        infotext1: string | undefined;
        infotext2: string | undefined;
        position: string | undefined;
        newsletter: boolean;
        flag: string | undefined;
        briefanrede: string | undefined;
        duForm: boolean;
        emails: PHPW.Api.Routes.Lib.ContactPerson.Model.Email[];
        phoneNumbers: PHPW.Api.Routes.Lib.Contacts.Model.PhoneNumber[];
        contacts: PHPW.Api.Routes.Lib.ContactPerson.Model.ContactPersonToContact[];
    }
    export interface ContactPersonToContact {
        contact: PHPW.Api.Routes.ComboboxModel.Contact;
        jobPosition: string | undefined;
    }
    export interface Email {
        address: string | undefined;
        id: number | undefined;
        name: string | undefined;
        noNewsDate: PHPW.ExtendedDateTime | undefined;
        typeId: PHPW.Entities.Enum.ContactMailAddressType | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Contacts {
    export interface CheckShortnameBody extends PHPW.Api.Bodies.Model {
        shortname: string;
        contactId: number | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Contacts.CheckVat.Model {
    export interface CheckVatServiceResponse extends PHPW.Api.Responses.DefaultResponse {
        countryCode: string;
        vatNumber: string;
        requestDate: PHPW.ExtendedDateTime;
        valid: boolean;
        name: string;
        address: string;
        requestIdentifier?: string | undefined;
        success: boolean;
    }
}
export namespace PHPW.Api.Routes.Lib.Contacts.Model {
    export interface Address {
        guid: string;
        firma1?: string | undefined;
        firma2?: string | undefined;
        firma3?: string | undefined;
        street?: string | undefined;
        ort?: string | undefined;
        postcode?: string | undefined;
        countryId?: number | undefined;
        designation?: string | undefined;
        briefanrede?: string | undefined;
        invoiceType: boolean;
        deliveryType: boolean;
    }
    export interface Bank {
        inhaber: string | undefined;
        bank: string | undefined;
        IBAN: string | undefined;
        BIC: string | undefined;
        mandRef: string | undefined;
        guid: string;
        mandStartDate: PHPW.ExtendedDateTime | undefined;
    }
    export interface Contact extends PHPW.Api.Lib.DefaultBody {
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUser?: number | undefined;
        updateDate?: PHPW.ExtendedDateTime | undefined;
        updateUser?: number | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: number | undefined;
        lieferstop: boolean;
        kurzname?: string | undefined;
        kundennummer?: number | undefined;
        bank: PHPW.Api.Routes.Lib.Contacts.Model.Bank[];
        fibuKonto?: number | undefined;
        website?: string | undefined;
        ustFrei: PHPW.Entities.Enum.ProcessTaxBehaviour;
        ustId?: string | undefined;
        taxId?: string | undefined;
        zahlungsart?: number | undefined;
        zahlungsziel?: number | undefined;
        vertraulich: boolean;
        kreditlimit?: number | undefined;
        ownSupplierNumber?: string | undefined;
        ownCustomerNumber?: string | undefined;
        businessRelation: PHPW.Entities.Enum.BusinessRelation;
        erechnungType: PHPW.Entities.Enum.ERechnungType;
        brutto: boolean;
        lieferUstFrei: PHPW.Entities.Enum.ProcessTaxBehaviour;
        lieferZahlungsart?: number | undefined;
        lieferZahlungsziel?: number | undefined;
        extra1: string | undefined;
        extra2?: string | undefined;
        extra3?: string | undefined;
        extra4?: string | undefined;
        extra5?: string | undefined;
        extra6?: string | undefined;
        extra7?: string | undefined;
        extra8?: string | undefined;
        extra9?: string | undefined;
        extra10?: string | undefined;
        infotext1?: string | undefined;
        infotext2?: string | undefined;
        infotext3?: string | undefined;
        importIdent?: string | undefined;
        aktionsInfo?: string | undefined;
        guid?: string | undefined;
        preisliste?: PHPW.Api.Routes.ComboboxModel.ArticleSellingPriceGroupName | undefined;
        keyAccountManager: PHPW.Api.Routes.ComboboxModel.User[];
        primaryInvoiceAddress: string | number | undefined;
        primaryDeliveryAddress: string | number | undefined;
        addresses: PHPW.Api.Routes.Lib.Contacts.Model.Address[];
        flags: PHPW.Api.Routes.ComboboxModel.ContactFlag[];
        emails: PHPW.Api.Routes.Lib.Contacts.Model.Email[];
        emailDomains: PHPW.Api.Routes.Lib.Contacts.Model.EmailDomain[];
        phoneNumbers: PHPW.Api.Routes.Lib.Contacts.Model.PhoneNumber[];
    }
    export interface ContactLink extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        disabled: boolean;
        baseContact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        contact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        reason: string | undefined;
    }
    export interface Email {
        address?: string | undefined;
        id?: number | undefined;
        name?: string | undefined;
        noNewsDate: PHPW.ExtendedDateTime | undefined;
        typeId: PHPW.Entities.Enum.ContactMailAddressType | undefined;
    }
    export interface EmailDomain {
        domain?: string | undefined;
        id?: number | undefined;
    }
    export interface ImportDryrun extends PHPW.Api.Bodies.Model {
        type?: string | undefined;
        settings: PHPW.Data.Io.Import.Contact.Model.ImportSettings;
    }
    export interface PhoneNumber {
        number?: string | undefined;
        dashOffset?: number | undefined;
        comment?: string | undefined;
        typeId?: PHPW.Entities.Enum.PhoneNumberType | undefined;
        id?: number | undefined;
    }
    export interface ValidateVatRequestParameters extends PHPW.Api.Lib.DefaultRequestParameters {
        vatNumber: string;
        iso_3166_2?: string | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Demand.Model {
    export interface Demand extends PHPW.Api.Bodies.Model {
        id: number;
        count: number;
        unit: PHPW.Api.Routes.ComboboxModel.Unit;
        user: PHPW.Api.Routes.ComboboxModel.User;
        article: PHPW.Api.Routes.ComboboxModel.Article;
        state: PHPW.Api.Routes.Lib.Demand.Model.DemandState;
        reason: string;
    }
    export interface DemandState extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        text: string;
    }
}
export namespace PHPW.Api.Routes.Lib.Disposition.Model {
    export interface DispoPriceGlobalList extends PHPW.Api.Bodies.Model {
        globalPrices: PHPW.Api.Routes.Lib.Article.Model.DispoPriceGlobal[];
    }
    export interface DispoProcessCalendarPosition {
        id?: number | undefined;
        invoiceStart?: PHPW.ExtendedDateTime | undefined;
        invoiceEnd?: PHPW.ExtendedDateTime | undefined;
        startDate?: PHPW.ExtendedDateTime | undefined;
        endDate?: PHPW.ExtendedDateTime | undefined;
        fullday?: boolean | undefined;
        name: string;
        color?: string | undefined;
        anzahl?: number | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Dms.Model {
    export interface GetDmsSearchParams extends PHPW.Api.Lib.DefaultRequestParameters {
        filters: {[key: string]: string};
        sort?: Array<[string, "ASC" | "DESC"]> | undefined;
        rowCount?: number;
        offset: number;
        special?: {[key: string]: boolean | number | string | Date | undefined | number[]};
    }
    export interface GetDmsSearchParamsSpecial {
        pathId: number;
        subpath: string;
    }
}
export namespace PHPW.Api.Routes.Lib.Document.Model {
    export interface AvisResponse extends PHPW.Api.Bodies.Model {
        mailInfos: PHPW.Api.Routes.Lib.Document.Model.MailInfo[];
        count: number;
    }
    export interface ERBBlockzahlungAvisResponse extends PHPW.Api.Responses.DefaultResponse {
        avisResponse: PHPW.Api.Routes.Lib.Document.Model.AvisResponse;
        skippedEntries: string[];
    }
    export interface GetDocumentERBBlockzahlungenAvisRequestBody extends PHPW.Api.Bodies.Model {
        elements: PHPW.Api.Routes.Lib.Document.Model.GetDocumentERBBlockzahlungenAvisRequestBodyElement[];
    }
    export interface GetDocumentERBBlockzahlungenAvisRequestBodyElement {
        bill_id: number;
        invoiceDate: PHPW.ExtendedDateTime;
        bill_number: string;
        date: PHPW.ExtendedDateTime;
        ausgleichBetrag: number;
        amount: number;
        skontoDate: PHPW.ExtendedDateTime | undefined;
        skontoAmount: number;
        contact: number;
        account: number;
    }
    export interface MahnungParams extends PHPW.Api.Lib.DefaultRequestParameters {
        ids: number[];
        mType: number;
        templateId: number;
        days: number;
    }
    export interface MailInfo {
        hash: string;
        contact: number;
    }
}
export namespace PHPW.Api.Routes.Lib.Files.Model {
    export interface File extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        storeDate: PHPW.ExtendedDateTime | undefined;
        filename: string;
        size: number;
        mimeType: string;
        hash: string;
        sha512: string;
        comment: string | undefined;
        shortInfo: string | undefined;
        expireDate: PHPW.ExtendedDateTime | undefined;
        typeId: number | undefined;
        private: boolean | undefined;
    }
    export interface FileInRelationTo extends PHPW.Api.Bodies.Model {
        public?: boolean;
        filename?: string | undefined;
        priority?: number;
        articleImage?: boolean;
        subId?: number | undefined;
        linkReason?: string | undefined;
    }
    export interface FilesHashLinkParams extends PHPW.Api.Lib.DefaultRequestParameters {
        targetId: number | undefined;
        targetType: string | undefined;
    }
    export interface GetFilesBundleDownloadParams extends PHPW.Api.Lib.DefaultRequestParameters {
        hash: string[];
    }
    export interface GetFilesHashRelationParams extends PHPW.Api.Lib.DefaultRequestParameters {
        article?: number | undefined;
        contact?: number | undefined;
        aufgabe?: number | undefined;
        articleSerial?: number | undefined;
        dispoArticleSerial?: number | undefined;
        project?: number | undefined;
        erb?: number | undefined;
    }
    export interface PostActionSetFileNameModel extends PHPW.Api.Bodies.Model {
        hash: string | undefined;
        filename: string;
    }
    export interface UploadFile extends PHPW.Api.Bodies.Model {
        info: string | undefined;
        comment: string | undefined;
        expireDate?: PHPW.ExtendedDateTime | undefined;
        private: boolean;
        typeId?: number | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Kasse.Model {
    export interface KasseArticle extends PHPW.Api.Bodies.Model {
        articleId: number | undefined;
        bezeichnung: string;
        price: number;
        taxId: number;
        hash: string;
    }
    export interface KassePlatz extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        name: string;
        printerId: string;
        barcodeConnected: boolean;
        displayConnected: boolean;
        kassenladeConnected: boolean;
        selectedPrinterType: number | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Lager.Model {
    export interface LagerPlatzBestand {
        id: number | undefined;
        article: PHPW.Api.Routes.ComboboxModel.Article;
        place: PHPW.Api.Routes.ComboboxModel.LagerPlatz;
        type: PHPW.Api.Routes.ComboboxModel.LagerPlatzBestandType;
        stock: number;
        charge: PHPW.Api.Routes.ComboboxModel.ArticleCharge | undefined;
        serial: PHPW.Api.Routes.ComboboxModel.ArticleSerial | undefined;
    }
    export interface Lagerplatz extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        name: string | undefined;
        type?: PHPW.Entities.Enum.LagerPlatzTyp;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        minAmount?: number | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: number | undefined;
    }
    export interface StockArrivalResult extends PHPW.Api.Bodies.Model {
        invalidArticles: PHPW.Api.Routes.ComboboxModel.Article[];
        collectionArticles: PHPW.Api.Routes.ComboboxModel.Article[];
        noInfos: PHPW.Api.Routes.ComboboxModel.Article[];
        findPersistExceptions: PHPW.Api.Routes.ComboboxModel.Article[];
        findPersistExceptionInfos: string[];
        stockActionExceptions: PHPW.Api.Routes.ComboboxModel.Article[];
        stockActionExceptionInfos: string[];
        successes: PHPW.Api.Routes.ComboboxModel.Article[];
        fails: PHPW.Api.Routes.ComboboxModel.Article[];
    }
    export interface StorageStockArrival extends PHPW.Api.Bodies.Model {
        targetPlace?: PHPW.Api.Routes.ComboboxModel.LagerPlatz | undefined;
        stockArrivals: PHPW.Api.Routes.Lib.Lager.Model.StorageStockArrivalArticle[];
    }
    export interface StorageStockArrivalArticle extends PHPW.Api.Bodies.Model {
        article: PHPW.Api.Routes.ComboboxModel.Article;
        noOrder: boolean;
        lieferant?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        process?: PHPW.Api.Routes.ComboboxModel.Process | undefined;
        lieferscheinNr?: string | undefined;
        kommission?: string | undefined;
        kommentar?: string | undefined;
        amount?: number | undefined;
        serials: PHPW.Api.Routes.Lib.Article.Model.WareneingangSerialOrCharge[];
    }
}
export namespace PHPW.Api.Routes.Lib.Mail.Model {
    export interface GetMailIdParams extends PHPW.Api.Lib.Common.ParamsSilent {
        forceFetch?: boolean | undefined;
    }
    export interface GetMailTemplatesResponse extends PHPW.Api.Responses.DefaultResponse {
        templates: {[key: string]: string};
    }
    export interface Mail extends PHPW.Api.Responses.DefaultResponse {
        id: number | undefined;
        from: PHPW.Api.Routes.Lib.Mail.Model.MailAddress[];
        to: PHPW.Api.Routes.Lib.Mail.Model.MailAddress[];
        cc: PHPW.Api.Routes.Lib.Mail.Model.MailAddress[];
        bcc: PHPW.Api.Routes.Lib.Mail.Model.MailAddress[];
        replyTo: PHPW.Api.Routes.Lib.Mail.Model.MailAddress[];
        deliveredTo: PHPW.Api.Routes.Lib.Mail.Model.MailAddress[];
        envelopeTo: PHPW.Api.Routes.Lib.Mail.Model.MailAddress[];
        hasExternalImages: boolean;
        date: PHPW.ExtendedDateTime | undefined;
        subject: string;
        statusId: PHPW.Entities.Enum.MailStatus;
        prio: number;
        shortinfo: string | undefined;
        comment: string | undefined;
        message: string | undefined;
        header: string | undefined;
        tasks: PHPW.Api.Routes.ComboboxModel.Aufgabe[];
        expireDays: number | undefined;
        relations: PHPW.Api.Routes.ComboboxModel.MailRelation[];
        attachments: PHPW.Api.Routes.Lib.Files.Model.File[];
        messageId: string;
        isPrivate: boolean;
        timings: {
            connectionDown?: number;
            connectionUp?: number;
            fetch?: number;
            fullFunction?: number;
            parse?: number;
        };
    }
    export interface MailAddress {
        id: number | undefined;
        name: string | undefined;
        address: string;
        contacts: Array<{id: number; kurzname: string; text: string}>;
    }
    export interface MailAddressEdit extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string | undefined;
        address: string;
        frequence: number | undefined;
        nonewsDate: PHPW.ExtendedDateTime | undefined;
        trash: boolean;
    }
    export interface MailAddressLinkContact extends PHPW.Api.Lib.DefaultBody {
        contact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        contactPerson: PHPW.Api.Routes.ComboboxModel.ContactPerson | undefined;
    }
    export interface MailDraft extends PHPW.Api.Lib.DefaultBody {
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUserId?: number | undefined;
        lastUpdate?: PHPW.ExtendedDateTime | undefined;
        lastUpdateUserId?: number | undefined;
        trash?: boolean | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUserId?: number | undefined;
        trashNote?: string | undefined;
        name?: string | undefined;
        subject: string;
        content: string;
        to: string[];
        cc: string[];
        bcc: string[];
        attachments: PHPW.Api.Routes.Lib.Mail.Model.Send.Attachment[];
    }
    export interface MailNewsletter extends PHPW.Api.Lib.DefaultBody {
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUserId?: number | undefined;
        lastUpdate?: PHPW.ExtendedDateTime | undefined;
        lastUpdateUserId?: number | undefined;
        trash?: boolean | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUserId?: number | undefined;
        trashNote?: string | undefined;
        uuid?: string | undefined;
        uuidDate?: PHPW.ExtendedDateTime | undefined;
        subject: string;
        content: string;
        priority: number;
        size?: number | undefined;
        from: PHPW.Api.Routes.Lib.Mail.Model.Send.Address;
        reply: PHPW.Api.Routes.Lib.Mail.Model.Send.Address;
        flags: PHPW.Api.Routes.ComboboxModel.ContactFlag[];
        attachments: PHPW.Api.Routes.Lib.Mail.Model.Send.Attachment[];
    }
    export interface MailRelation extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        short: string | undefined;
        long: string | undefined;
        expireText: number | undefined;
        expireAttachments: number | undefined;
    }
    export interface PostActionNewsletterSubscribeBody extends PHPW.Api.Lib.DefaultBody {
        addressen: string;
    }
    export interface PostMailAddressResponse extends PHPW.Api.Lib.DefaultBody {
        notifications: ["mailAddressDeleted", "mailAddressUndeleted", "mailNonewsDateRemove", "mailNonewsDateAdd"];
    }
    export interface PostMailIdActionSetstateParams extends PHPW.Api.Lib.DefaultRequestParameters {
        manuallySet: boolean;
        statusId: PHPW.Entities.Enum.MailStatus;
    }
}
export namespace PHPW.Api.Routes.Lib.Mail.Model.Send {
    export interface Address {
        address: string;
        name: string | undefined;
    }
    export interface Attachment {
        data?: string | undefined;
        hash?: string | undefined;
        filename?: string | undefined;
    }
    export interface Mail extends PHPW.Api.Lib.DefaultBody {
        priority: number;
        from: PHPW.Api.Routes.Lib.Mail.Model.Send.Address;
        to: string[];
        cc: string[];
        bcc: string[];
        inReplyTo: string | undefined;
        subject: string;
        content: string;
        attachments: PHPW.Api.Routes.Lib.Mail.Model.Send.Attachment[];
        tasks: PHPW.Api.Routes.ComboboxModel.Aufgabe[];
        shortinfo: string | undefined;
        comment: string | undefined;
        expireDays: number | undefined;
        relations: PHPW.Api.Routes.ComboboxModel.MailRelation[];
    }
}
export namespace PHPW.Api.Routes.Lib.Mail.Response {
    export interface CollectedAddressees extends PHPW.Api.Responses.DefaultResponse {
        count: number;
        unSend: number | undefined;
        uuid: string | undefined;
        uuidDate: PHPW.ExtendedDateTime | undefined;
    }
    export interface NewsletterSend extends PHPW.Api.Responses.DefaultResponse {
        uuid: string | undefined;
        uuidDate: PHPW.ExtendedDateTime | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Parcel.Model {
    export interface Address {
        firma1?: string | undefined;
        firma2?: string | undefined;
        firma3?: string | undefined;
        countryId?: number | undefined;
        ort?: string | undefined;
        plz?: string | undefined;
        strasse?: string | undefined;
        phone?: string | undefined;
        email?: string | undefined;
    }
    export interface Package {
        weight?: number | undefined;
        length?: number | undefined;
        width?: number | undefined;
        height?: number | undefined;
        type?: string | undefined;
    }
    export interface Parcel extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        to: PHPW.Api.Routes.Lib.Parcel.Model.Address;
        package: PHPW.Api.Routes.Lib.Parcel.Model.Package;
        reference_number?: string | undefined;
        labelUrl?: string | undefined;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        process?: PHPW.Api.Routes.ComboboxModel.Process | undefined;
        providerId?: string | undefined;
        providerDisplayName?: string | undefined;
        carrierId?: string | undefined;
        carrierDisplayName?: string | undefined;
        serviceId?: string | number | undefined;
        serviceDisplayName?: string | undefined;
        pickupTime?: PHPW.ExtendedDateTime | undefined;
        price?: number | undefined;
    }
    export interface PreviewResponse extends PHPW.Api.Bodies.Model {
        labelUrl: string;
    }
}
export namespace PHPW.Api.Routes.Lib.Payment.Model {
    export interface DocumentCircuit {
        label: string;
        value: string;
    }
    export interface FastPayment extends PHPW.Api.Bodies.Model {
        bill_id: number;
        nummer: string | undefined;
        date: PHPW.ExtendedDateTime | undefined;
        totalAmount: number;
        amount: number;
        bank_id: number | undefined;
        skontoAmount: number;
        paymentDate: PHPW.ExtendedDateTime;
        paymentAmount: number;
        proformaPayment?: PHPW.Api.Routes.Lib.Payment.Model.ProformaInvoiceDetails | undefined;
    }
    export interface Payment {
        bill_id: number | undefined;
        date: PHPW.ExtendedDateTime | undefined;
        skontoAmount?: number | undefined;
        amount: number | undefined;
        account: number;
    }
    export interface PaymentDefault extends PHPW.Api.Bodies.Model {
        date: PHPW.ExtendedDateTime;
        skontoKonto: number;
        bankKonto?: number | undefined;
        belegkreis?: number | undefined;
    }
    export interface PaymentListOverView extends PHPW.Api.Bodies.Model {
        paymentList: PHPW.Api.Routes.Lib.Payment.Model.Payment[];
        active: number;
        date: PHPW.ExtendedDateTime;
        belegInfo: string;
        belegkreis: number;
        bank_id: number;
        done: number;
        executionDate: PHPW.ExtendedDateTime;
        sepaCollectionDate: PHPW.ExtendedDateTime | undefined;
        sepaSeparate: boolean;
    }
    export interface PaymentView {
        bill_id: number | undefined;
        date: PHPW.ExtendedDateTime | undefined;
        skontoDate?: PHPW.ExtendedDateTime | undefined;
        contact?: number | undefined;
        shortname?: string | undefined;
        bill_number?: string | undefined;
        skontoAmount?: number | undefined;
        amount: number;
        account: number;
        openAmount: number;
        totalAmount: number;
        ausgleichBetrag: number;
        invoiceDate: PHPW.ExtendedDateTime;
        skontoKonto: number;
        skontoToleranz: PHPW.ExtendedDateTime;
        origSkonto: number;
        matureDate: PHPW.ExtendedDateTime;
        sepaB2B?: boolean | undefined;
    }
    export interface ProformaInvoiceDetails extends PHPW.Api.Bodies.Model {
        text: string;
        id: number | undefined;
        proformaPayment: PHPW.Api.Routes.Lib.Booking.Model.ProformaPayment | undefined;
    }
    export interface SepaExportInfo extends PHPW.Api.Bodies.Model {
        file_id?: number | undefined;
        errors: string[];
        avisDemands: PHPW.Api.Routes.Lib.Payment.Model.SepaPaymentInfo[];
        emptyBook: PHPW.Api.Routes.Lib.Payment.Model.SepaPaymentInfo[];
    }
    export interface SepaPaymentInfo {
        rgNumbers: string[];
        amount: number;
        iban: string;
        name: string;
        inhaber: string;
    }
}
export namespace PHPW.Api.Routes.Lib.Preload.Lib {
    export interface Account extends PHPW.Api.Lib.DefaultBody {
        id: number;
        number: number;
        name: string;
        lastYear: number | undefined;
    }
    export interface ArticleUnit extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        trash: boolean;
        short: string;
        long: string;
    }
    export interface BookingIncomingBillsStatus extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string;
        color: string;
    }
    export interface CostCenter extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string;
        short: string;
    }
    export interface DispoProcessStatus extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string;
        color: string;
        viewstate: number;
    }
    export interface DispoStatus extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string;
        color: string;
    }
    export interface DocumentCircuit extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string;
        short: string;
    }
    export interface Gesellschafter extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string;
    }
    export interface OrderPayment extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string | undefined;
    }
    export interface ProcessStatus extends PHPW.Api.Lib.DefaultBody {
        id?: number | undefined;
        name: string;
        color: string;
    }
    export interface ProcessTemplate extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string;
        template: string;
        templateGroupId: number | undefined;
        version: PHPW.Entities.Enum.ProcessTemplateType;
    }
    export interface ProcessType extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        trash: boolean;
        name: string;
        short: string;
        useEk: boolean;
        category: PHPW.Process.Model.ProcessTypeCategory | undefined;
        useRelPayText: boolean;
        template: number | undefined;
        documentSettings: PHPW.Document.Model.ProcessSettings;
    }
    export interface ProjectStatus extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        text: string;
    }
    export interface Stock extends PHPW.Api.Lib.DefaultBody {
        id: string;
        name: string;
    }
    export interface User extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        vorname: string;
        name: string;
        kuerzel: string;
        trashed: boolean;
    }
}
export namespace PHPW.Api.Routes.Lib.Preload.Model {
    export interface UneceRec20 {
        code: string;
        name: string;
    }
    export interface Untdid1001 {
        code: number;
        name: string;
        description: string;
    }
    export interface Untdid4461 {
        code: string;
        name: string;
        description: string;
    }
}
export namespace PHPW.Api.Routes.Lib.Process.Model {
    export enum PositionAvailability {
        "FULLY_AVAILABLE" = 1,
        "PARTIALLY_AVAILABLE" = 2,
        "IN_FUTURE_AVAILABLE" = 3,
        "NOT_AVAILABLE" = 4,
        "NO_ARTICLE" = 5,
        "NOT_STOCKABLE" = 6,
        "ERROR" = 7
    }
    export interface Address {
        firma1?: string | undefined;
        firma2?: string | undefined;
        firma3?: string | undefined;
        countryId?: number | undefined;
        ort?: string | undefined;
        plz?: string | undefined;
        strasse?: string | undefined;
    }
    export interface Article {
        id?: number | undefined;
        snPflege: PHPW.Api.Routes.Lib.Article.Lib.EArticleType;
        vpeSize: number;
        gewicht?: number | undefined;
        skontierbar: boolean;
        trash: boolean;
        calculatedLohnminuten?: number | undefined;
        extraCost?: number | undefined;
        extraCostTax?: number | undefined;
        prices: PHPW.Price.Model.PriceGroup[];
        priceContacts: PHPW.Price.Model.PriceContact[];
        images: PHPW.Api.Routes.Lib.Article.Model.ArticleImage[];
    }
    export interface BodySetAmountOutstanding extends PHPW.Api.Lib.DefaultBody {
        amountOutstanding?: number | undefined;
        comment?: string | undefined;
    }
    export interface CollectionPosition {
        id?: number | undefined;
        index?: number | undefined;
        amount: number;
        unitId?: number | undefined;
        article?: PHPW.Api.Routes.Lib.Process.Model.Article | undefined;
        articleNumber?: string | undefined;
        customerArticleNumber?: string | undefined;
        customerProcessNumber?: string | undefined;
        cashback?: number | undefined;
        freightCharge: number;
        calculationText?: string | undefined;
        freightChargePerUnit: boolean;
        name?: string | undefined;
        brand?: string | undefined;
        description?: string | undefined;
        taxId?: number | undefined;
        posiTyp: PHPW.Process.Model.ProcessPositionType;
        ekPreis: PHPW.Process.Model.PositionPreis;
        vkPreis: PHPW.Process.Model.PositionPreis;
    }
    export interface DocumentActionResponse extends PHPW.Api.Responses.ActionResponse {
        documentHash: string | undefined;
    }
    export interface ParamsGetPosition extends PHPW.Api.Lib.DefaultRequestParameters {
        selfParentLinkAsNew?: boolean | undefined;
    }
    export interface ParentPositionInfo {
        id?: number | undefined;
        processId?: number | undefined;
        contactId?: number | undefined;
        specList: PHPW.Entities.Enum.ProcessCategory;
        positionPosition: string;
        processNumber?: string | undefined;
        processDate?: PHPW.ExtendedDateTime | undefined;
    }
    export interface Payment extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        name: string;
        target?: number | undefined;
        targetWeekdays: number[];
        text: string;
        textEn: string;
        relativeText: string;
        relativeTextEn: string;
        requireKundennummer?: boolean;
        selectable?: boolean;
        showBankOnDocument: boolean;
        bankId?: number | undefined;
        skontoTarget?: number | undefined;
        defaultSkonto?: number | undefined;
        startNextMonth?: boolean;
        orderby?: number | undefined;
        untdid4461: string;
        default?: boolean;
    }
    export interface Position extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        processId?: number | undefined;
        customPosText?: string | undefined;
        amount: number;
        countOutstanding?: number | undefined;
        unitId?: number | undefined;
        article?: PHPW.Api.Routes.Lib.Process.Model.Article | undefined;
        articleNumber?: string | undefined;
        customerArticleNumber?: string | undefined;
        customerProcessNumber?: string | undefined;
        cashback?: number | undefined;
        freightCharge: number;
        calculationText?: string | undefined;
        freightChargePerUnit: boolean;
        name?: string | undefined;
        brand?: string | undefined;
        description?: string | undefined;
        taxId?: number | undefined;
        chargen: PHPW.Api.Routes.Lib.Process.Model.PositionCharge[];
        serialNumbers: PHPW.Api.Routes.Lib.Process.Model.PositionSerial[];
        posiTyp: PHPW.Process.Model.ProcessPositionType;
        parentPositionInfo?: PHPW.Api.Routes.Lib.Process.Model.ParentPositionInfo | undefined;
        shippingPlan?: PHPW.Api.Routes.Lib.Process.Model.PositionShippingPlan | undefined;
        dispoPosition?: PHPW.Api.Routes.Lib.Process.Model.PositionDispo | undefined;
        ekPreis: PHPW.Process.Model.PositionPreis;
        vkPreis: PHPW.Process.Model.PositionPreis;
        positionGroupId?: number | undefined;
        positionGroup?: PHPW.Api.Routes.Lib.Process.Model.PositionGroup | undefined;
        subscription?: PHPW.Api.Routes.Lib.Subscription.Model.Subscription | undefined;
        subscriptionCoveredUntilDate?: PHPW.ExtendedDateTime | undefined;
    }
    export interface PositionCharge {
        batch: PHPW.Api.Routes.ComboboxModel.ArticleCharge;
        amount: number;
    }
    export interface PositionDispo {
        billingFrom?: PHPW.ExtendedDateTime | undefined;
        billingTo?: PHPW.ExtendedDateTime | undefined;
        billingCount: number;
        from?: PHPW.ExtendedDateTime | undefined;
        to?: PHPW.ExtendedDateTime | undefined;
        customDayPrice?: number | undefined;
        id?: number | undefined;
        billingType: PHPW.Entities.Enum.DispoBillingType;
    }
    export interface PositionGroup extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        deliverable: boolean;
        fulfillmentDelivery: PHPW.Entities.Enum.FulfillmentState;
        fulfillmentInvoice: PHPW.Entities.Enum.FulfillmentState;
    }
    export interface PositionSerial {
        serial?: PHPW.Api.Routes.ComboboxModel.ArticleSerial | undefined;
        serialNo: string;
        showInfo: boolean;
        infoText?: string | undefined;
    }
    export interface PositionShippingPlan {
        shippingDate?: PHPW.ExtendedDateTime | undefined;
        shippingDateType?: PHPW.Entities.Enum.ProcessPositionShippingDateType | undefined;
        shippingType?: PHPW.Entities.Enum.ProcessPositionShippingType | undefined;
    }
    export interface Process extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        parentProcess?: PHPW.Api.Routes.ComboboxModel.ParentProcess | undefined;
        typeId?: number | undefined;
        positions: PHPW.Api.Routes.Lib.Process.Model.Position[];
        shippingDate?: string | undefined;
        deliveryAddress?: PHPW.Api.Routes.Lib.Process.Model.Address | undefined;
        deliveryAddressEqualsToInvoice: boolean;
        invoiceAddress: PHPW.Api.Routes.Lib.Process.Model.Address;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        cancelDate?: PHPW.ExtendedDateTime | undefined;
        invoiceDate?: PHPW.ExtendedDateTime | undefined;
        shortName: string;
        besteller?: string | undefined;
        emails: string[];
        invoiceEmails: string[];
        buyerReference?: string | undefined;
        projectReference?: string | undefined;
        contractReference?: string | undefined;
        purchaseOrderReference?: string | undefined;
        tenderOrLotReference?: string | undefined;
        ownSupplierNumber?: string | undefined;
        ownCustomerNumber?: string | undefined;
        customerAccountId?: number | undefined;
        accountId?: number | undefined;
        tasks: PHPW.Api.Routes.ComboboxModel.Aufgabe[];
        taxFree: PHPW.Entities.Enum.ProcessTaxBehaviour;
        taxId?: string | undefined;
        rmaId?: number | undefined;
        documentNumber?: string | undefined;
        templateId?: number | undefined;
        correctionReason?: string | undefined;
        angebotGueltigkeit?: number | undefined;
        prepayment: number;
        language: PHPW.Entities.Enum.ArticleLanguage;
        paymentId?: number | undefined;
        cashback?: number | undefined;
        discount?: number | undefined;
        bankId?: number | undefined;
        paymentPeriod?: number | undefined;
        cashbackPeriod?: number | undefined;
        paymentText?: string | undefined;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        contactPerson?: PHPW.Api.Routes.ComboboxModel.ContactPerson | undefined;
        deductIds: number[];
        documentSettings?: PHPW.Document.Model.ProcessSettings | undefined;
        specList: PHPW.Entities.Enum.ProcessCategory;
        documentHash?: string | undefined;
        xmlHash?: string | undefined;
        cancelDocumentHash?: string | undefined;
        agreement?: string | undefined;
        extras?: PHPW.Api.Routes.Lib.Process.Model.ProcessExtras | undefined;
        amountBrutto?: number | undefined;
        amountNetto?: number | undefined;
        cashReceived?: number | undefined;
        cashPaymentType?: number | undefined;
        comment?: string | undefined;
        groupId?: number | undefined;
    }
    export interface ProcessCheck extends PHPW.Api.Lib.DefaultBody {
        positions: PHPW.Api.Routes.Lib.Process.Model.ProcessCheckPosition[];
        contactId?: number | undefined;
        parentProcessId?: number | undefined;
        language: PHPW.Entities.Enum.ArticleLanguage;
    }
    export interface ProcessCheckPosition {
        amount: number;
        price: number;
        tax: number;
        taxId: number;
        articleId?: number | undefined;
        subscriptionId?: number | undefined;
        priceList?: string | undefined;
        dispoFrom?: PHPW.ExtendedDateTime | undefined;
        dispoTo?: PHPW.ExtendedDateTime | undefined;
    }
    export interface ProcessCheckResponse extends PHPW.Api.Responses.DefaultResponse {
        positions: PHPW.Api.Routes.Lib.Process.Model.ProcessCheckResponsePosition[];
    }
    export interface ProcessCheckResponsePosition {
        discounts: number[];
        sellingPrice?: number | undefined;
        sellingPriceList?: PHPW.Api.Routes.ComboboxModel.ArticleSellingPriceGroupName | undefined;
        sellingPriceContactContact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        buyingPrice?: number | undefined;
        availability: PHPW.Api.Routes.Lib.Process.Model.ProcessCheckResponsePositionAvailability;
        brand: string | undefined;
        name: string | undefined;
        text: string | undefined;
    }
    export interface ProcessCheckResponsePositionAvailability {
        stock: number;
        availability: PHPW.Api.Routes.Lib.Process.Model.PositionAvailability;
        details: {[key: number]: number};
    }
    export interface ProcessCollection extends PHPW.Api.Lib.DefaultBody {
        id?: number | undefined;
        name?: string | undefined;
        positions: PHPW.Api.Routes.Lib.Process.Model.CollectionPosition[];
    }
    export interface ProcessCreateResponse extends PHPW.Api.Responses.SaveResponse {
        documentHash: string | undefined;
    }
    export interface ProcessExtras extends PHPW.Api.Bodies.Model {
        date1?: PHPW.ExtendedDateTime | undefined;
        number1?: number | undefined;
        text1?: string | undefined;
        date2?: PHPW.ExtendedDateTime | undefined;
        number2?: number | undefined;
        text2?: string | undefined;
        text3?: string | undefined;
        text4?: string | undefined;
    }
    export interface ProcessGroupData extends PHPW.Api.Lib.DefaultBody {
        id?: number | undefined;
        insertDate: PHPW.ExtendedDateTime;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        comment?: string | undefined;
        deliveryAddresses: PHPW.Api.Routes.Lib.Process.Model.Address[];
        invoiceAddresses: PHPW.Api.Routes.Lib.Process.Model.Address[];
        offer: PHPW.Api.Routes.Lib.Process.Model.Process[];
        confirmation: PHPW.Api.Routes.Lib.Process.Model.Process[];
        invoice: PHPW.Api.Routes.Lib.Process.Model.Process[];
        delivery: PHPW.Api.Routes.Lib.Process.Model.Process[];
        ungrouped: PHPW.Api.Routes.Lib.Process.Model.Process[];
    }
    export interface ProcessGroupPositionGroupMerge extends PHPW.Api.Lib.DefaultBody {
        ids: number[];
    }
    export interface ProcessGroupSaveData extends PHPW.Api.Lib.DefaultBody {
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        comment?: string | undefined;
        selected: {[key: string]: boolean};
    }
    export interface ProcessGroupUnfinishedPositionsParams extends PHPW.Api.Lib.DefaultRequestParameters {
        targetArcType: PHPW.Process.Model.ProcessTypeArcType | undefined;
    }
    export interface ProcessLinked extends PHPW.Api.Bodies.Model {
        hasParent: boolean;
        hasChildren: boolean;
    }
}
export namespace PHPW.Api.Routes.Lib.Protocol.Model {
    export interface Protocol extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        aufgabe: PHPW.Api.Routes.ComboboxModel.Aufgabe | undefined;
        information: string | undefined;
        startTime: PHPW.ExtendedDateTime | undefined;
        endTime: PHPW.ExtendedDateTime | undefined;
        job: PHPW.Api.Routes.ComboboxModel.ProtocolJob | undefined;
        article: PHPW.Api.Routes.ComboboxModel.Article | undefined;
        contact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        project: PHPW.Api.Routes.ComboboxModel.ProtocolProject | undefined;
        user: PHPW.Api.Routes.ComboboxModel.User | undefined;
    }
    export interface ProtocolBatchEdit extends PHPW.Api.Lib.DefaultBody {
        contact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        contactActive: boolean;
        job: PHPW.Api.Routes.ComboboxModel.ProtocolJob | undefined;
        jobActive: boolean;
        project: PHPW.Api.Routes.ComboboxModel.ProtocolProject | undefined;
        projectActive: boolean;
        task: PHPW.Api.Routes.ComboboxModel.Aufgabe | undefined;
        taskActive: boolean;
        article: PHPW.Api.Routes.ComboboxModel.Article | undefined;
        articleActive: boolean;
    }
    export interface ProtocolConcat extends PHPW.Api.Lib.DefaultBody {
        information: string;
    }
    export interface ProtocolDay extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        date: PHPW.ExtendedDateTime;
        user: PHPW.Api.Routes.ComboboxModel.User | undefined;
        start: PHPW.ExtendedDateTime | undefined;
        end: PHPW.ExtendedDateTime | undefined;
        flag: string | undefined;
        protocol: PHPW.Api.Routes.Lib.Protocol.Model.ProtocolEvent[];
    }
    export interface ProtocolEvent {
        id: number | undefined;
        aufgabe: PHPW.Api.Routes.ComboboxModel.Aufgabe | undefined;
        information: string | undefined;
        start: PHPW.ExtendedDateTime | undefined;
        end: PHPW.ExtendedDateTime | undefined;
        job: string | undefined;
        contact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        project: string | undefined;
    }
    export interface ProtocolSetState extends PHPW.Api.Lib.DefaultBody {
        protocolState: PHPW.Api.Routes.ComboboxModel.ProtocolState | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Report.Model {
    export enum ExportType {
        "pdf" = "pdf",
        "xlsx" = "xlsx",
        "csv" = "csv",
        "xml" = "xml"
    }
    export interface CommonRequestParameters extends PHPW.Api.Routes.Lib.Report.Model.CommonRequestParameters2 {
        start: PHPW.ExtendedDateTime;
        end: PHPW.ExtendedDateTime;
    }
    export interface CommonRequestParameters2 extends PHPW.Api.Lib.DefaultRequestParameters {
        exportType: PHPW.Api.Routes.Lib.Report.Model.ExportType;
    }
    export interface GetCategoriesResponse extends PHPW.Api.Bodies.Model {
        categories: {[key: string]: string};
    }
    export interface GetReportUstvaYearArguments extends PHPW.Api.Lib.DefaultRequestArguments {
        year: number;
    }
    export interface GetReportUstvaYearParameters extends PHPW.Api.Lib.DefaultRequestParameters {
        start: PHPW.ExtendedDateTime;
        end: PHPW.ExtendedDateTime;
        outputFormat: string;
        key_10: boolean;
        key_22: boolean;
        key_23: boolean;
        key_26: boolean;
        key_29: boolean;
    }
    export interface KontenAnzeigeSetting extends PHPW.Api.Lib.DefaultBody {
        dateStart: PHPW.ExtendedDateTime;
        dateEnd: PHPW.ExtendedDateTime;
        exportType: PHPW.Api.Routes.Lib.Report.Model.ExportType;
        chart: boolean;
        accounts: PHPW.Api.Routes.ComboboxModel.Konto[];
    }
    export interface KontoCategoryRange {
        name: string;
        start: number;
        end: number;
        accounts: PHPW.Booking.Kontenplan.Model.InitialKonto[];
    }
    export interface ProcessOverview2 extends PHPW.Api.Lib.DefaultBody {
        dateStart: PHPW.ExtendedDateTime;
        dateEnd: PHPW.ExtendedDateTime;
        timeframe: string;
        processType: string | undefined;
        contact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        user: PHPW.Api.Routes.ComboboxModel.User | undefined;
    }
    export interface SummenSaldenRequestParameters extends PHPW.Api.Routes.Lib.Report.Model.CommonRequestParameters {
        hideNullSaldo?: boolean;
        hideNotBooked?: boolean;
        categoryShowList: string[];
        ignoreEbWertForSaldo?: boolean;
        minusNegieren?: boolean;
    }
}
export namespace PHPW.Api.Routes.Lib.Rma.Model {
    export interface Rma {
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertBy?: number | undefined;
        lastUpdate?: PHPW.ExtendedDateTime | undefined;
        lastUpdateUser?: number | undefined;
        firma1?: string | undefined;
        strasse?: string | undefined;
        plz?: string | undefined;
        ort?: string | undefined;
        ansprechpartner?: string | undefined;
        telefon?: PHPW.Api.Routes.Lib.Contacts.Model.PhoneNumber | undefined;
        fax?: PHPW.Api.Routes.Lib.Contacts.Model.PhoneNumber | undefined;
        email?: string | undefined;
        marke?: string | undefined;
        typ?: string | undefined;
        lieferzustand?: string | undefined;
        lieferumfang?: string | undefined;
        serial?: string | undefined;
        fehlerangabe?: string | undefined;
        etLieferant?: string | undefined;
        etInfos?: string | undefined;
        webRmaNr?: string | undefined;
        webfileId?: string | undefined;
        eigFehlerangabe?: string | undefined;
        zeitaufwand?: number | undefined;
        tatsLeistung?: string | undefined;
        measurementIn?: PHPW.Api.Routes.Lib.Rma.Model.RmaMessung | undefined;
        measurementOut?: PHPW.Api.Routes.Lib.Rma.Model.RmaMessung | undefined;
        extRepNr?: string | undefined;
    }
    export interface RmaLamp {
        id?: number | undefined;
        lampenStundenAkt?: number | undefined;
    }
    export interface RmaLumen {
        id?: number | undefined;
        obenLinks?: number | undefined;
        obenMitte?: number | undefined;
        obenRechts?: number | undefined;
        mitteLinks?: number | undefined;
        mitte?: number | undefined;
        mitteRechts?: number | undefined;
        untenLinks?: number | undefined;
        untenMitte?: number | undefined;
        untenRechts?: number | undefined;
    }
    export interface RmaMessung {
        id?: number | undefined;
        lampenStundenMax?: number | undefined;
        timestamp?: PHPW.ExtendedDateTime | undefined;
        screenSizeH?: number | undefined;
        screenSizeB?: number | undefined;
        lensType?: string | undefined;
        lampe: PHPW.Api.Routes.Lib.Rma.Model.RmaLamp[];
        lumen?: PHPW.Api.Routes.Lib.Rma.Model.RmaLumen | undefined;
        filter?: number | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Setup.Model {
    export interface AufgabeStatus {
        id?: number | undefined;
        typeId?: number | undefined;
        text: string;
        color: string;
        orderBy?: number | undefined;
        done: boolean;
        autoMailStateId?: PHPW.Entities.Enum.MailStatus | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: PHPW.Api.Routes.ComboboxModel.User | undefined;
        trashNote?: string | undefined;
    }
    export interface AufgabeTyp {
        id?: number | undefined;
        orderBy?: number | undefined;
        text?: string | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: PHPW.Api.Routes.ComboboxModel.User | undefined;
        trashNote?: string | undefined;
    }
    export interface Bank {
        id: number | undefined;
        bankName: string;
        inhaber: string;
        iban: string;
        bic: string;
        short: string;
        orderBy: number;
        active: boolean;
        buhaKonto: PHPW.Api.Routes.ComboboxModel.Konto | undefined;
        arDocumentCircuit: number | undefined;
        erDocumentCircuit: number | undefined;
    }
    export interface DeviceType extends PHPW.Api.Bodies.Model {
        isPhone: boolean;
        isTablet: boolean;
    }
    export interface Imap {
        id?: number | undefined;
        host: string;
        port: number;
        username: string;
        password?: string | undefined;
        hasPassword: boolean;
        encryption?: PHPW.Entities.Enum.MailImapAccountEncryption | undefined;
        notValidateCert: boolean;
        inuse: boolean;
        comment?: string | undefined;
    }
    export interface ImapMailbox {
        id?: number | undefined;
        mial_id: number | undefined;
        uidvalidity: string;
        name: string;
        specialUse: string | undefined;
        deleted_on_server: boolean;
        disabled: boolean;
        modseq?: string | undefined;
    }
    export interface Payment extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        name: string;
        target: number | undefined;
        targetWeekdays: number[];
        text: string;
        textEn: string;
        relativeText: string;
        relativeTextEn: string;
        requireKundennummer: boolean;
        selectable: boolean;
        showBankOnDocument: boolean;
        bankId: number | undefined;
        skontoTarget: number | undefined;
        defaultSkonto: number | undefined;
        startNextMonth: boolean;
        orderby: number | undefined;
        untdid4461: string;
        sepaB2B: boolean;
        default: boolean;
    }
    export interface Tax {
        id?: number | undefined;
        name: string;
        satz: number;
        isDefault: boolean;
        isOSS: boolean;
        countryId?: number | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Staff.Model {
    export interface GetStaffCalendarRequestParams extends PHPW.Api.Lib.DefaultRequestParameters {
        date: PHPW.ExtendedDateTime;
        days: number;
        status: number[];
    }
    export interface StaffCalendarRow extends PHPW.Api.Lib.DefaultBody {
        userId?: number | undefined;
        selected: boolean;
        id?: number | undefined;
        name: string;
        positions: PHPW.Api.Routes.Lib.Disposition.Model.DispoProcessCalendarPosition[];
        commission: string;
    }
    export interface StaffEvent extends PHPW.Api.Lib.DefaultBody {
        id?: number | undefined;
        statusId?: number | undefined;
        name?: string | undefined;
        start: PHPW.ExtendedDateTime | undefined;
        end: PHPW.ExtendedDateTime | undefined;
        fullday: boolean;
        user?: PHPW.Api.Routes.ComboboxModel.User | undefined;
        approved: boolean;
        approvedUser?: PHPW.Api.Routes.ComboboxModel.User | undefined;
        approvedDate?: PHPW.ExtendedDateTime | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Subscription.Model {
    export interface Subscription extends PHPW.Api.Bodies.Model {
        contact: PHPW.Api.Routes.ComboboxModel.Contact;
        start: PHPW.ExtendedDateTime | undefined;
        startBilling?: PHPW.ExtendedDateTime | undefined;
        billingInterval: string;
        initialDuration: string;
        durationExtension: string;
        end?: PHPW.ExtendedDateTime | undefined;
        endFinal?: PHPW.ExtendedDateTime | undefined;
        amount: number;
        price: number;
        priceInterval?: string | undefined;
        priceFuture?: number | undefined;
        priceFutureActivation?: PHPW.ExtendedDateTime | undefined;
        discountFactorRelativ?: number | undefined;
        discountFactorRelativDeactivation?: PHPW.ExtendedDateTime | undefined;
        article: PHPW.Api.Routes.ComboboxModel.Article;
        articleBrand?: string | undefined;
        articleNumber: string;
        articleName: string;
        articleText: string;
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        insertUser?: number | undefined;
        updateDate?: PHPW.ExtendedDateTime | undefined;
        updateUser?: number | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashUser?: number | undefined;
        trashNote?: string | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Suggest.Model {
    export enum EGetSuggestIntrahandelZtn_BezeichnungArguments {
        "Ztn" = "ztn",
        "Bez" = "bezeichnung"
    }
    export interface ArgumentsAId extends PHPW.Api.Routes.Lib.Suggest.Model.ArgumentsAufId {
        aId: number | undefined;
    }
    export interface ArgumentsAufId extends PHPW.Api.Lib.DefaultRequestArguments {
        aufId: number;
    }
    export interface ArgumentsContactId extends PHPW.Api.Lib.DefaultRequestArguments {
        contact_id?: number | undefined;
    }
    export interface FilterParams extends PHPW.Api.Lib.DefaultRequestParameters {
        filter: string | undefined;
    }
    export interface GetSuggestArticleStockPlacesParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        stockTypeIds?: number[] | undefined;
        placeTypes?: number[] | undefined;
        intern?: boolean | undefined;
        extern?: boolean | undefined;
        available?: boolean | undefined;
        disposition?: boolean | undefined;
        onlyInStock?: boolean | undefined;
    }
    export interface GetSuggestArticleStockTypesParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        placeIds?: number[] | undefined;
        placeTypes?: number[] | undefined;
        intern?: boolean | undefined;
        extern?: boolean | undefined;
        available?: boolean | undefined;
        disposition?: boolean | undefined;
        onlyInStock?: boolean | undefined;
    }
    export interface GetSuggestArticlesIdChargeParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        onlyInStock?: boolean | undefined;
    }
    export interface GetSuggestArticlesIdSerialParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        onlyInStock?: boolean | undefined;
        notInEr?: boolean | undefined;
    }
    export interface GetSuggestArticlesParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        lang: PHPW.Entities.Enum.ArticleLanguage;
        protocol: boolean;
    }
    export interface GetSuggestIntrahandelZtn_BezeichnungArguments extends PHPW.Api.Lib.DefaultRequestArguments {
        type: PHPW.Api.Routes.Lib.Suggest.Model.EGetSuggestIntrahandelZtn_BezeichnungArguments;
        articleId: number | undefined;
    }
    export interface GetSuggestIntrahandelZtn_BezeichnungParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        extra: string | undefined;
    }
    export interface GetSuggestKontoParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        year?: number | undefined;
    }
    export interface GetSuggestProcessesParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        processTypeCategory?: undefined | PHPW.Process.Model.ProcessTypeCategory[];
        articleIds?: undefined | number[];
    }
    export interface GetSuggestStockPlacesParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        types: number[];
    }
    export interface GetSuggestStockTypesParams extends PHPW.Api.Routes.Lib.Suggest.Model.FilterParams {
        intern?: boolean | undefined;
        extern?: boolean | undefined;
        available?: boolean | undefined;
        disposition?: boolean | undefined;
    }
}
export namespace PHPW.Api.Routes.Lib.Table.Model {
    export interface ArgumentsProfile extends PHPW.Api.Lib.DefaultRequestArguments {
        profile: string;
    }
    export interface CountResponse extends PHPW.Api.Bodies.Model {
        success: boolean;
        count: number;
    }
    export interface DeleteTableNameSavedidsIdArguments extends PHPW.Api.Lib.DefaultRequestArguments {
        id: number;
        name: string;
    }
    export interface GetTableNameProfileProfileArguments extends PHPW.Api.Lib.DefaultRequestArguments {
        name: string;
        profile: string;
    }
    export interface GetTableNameProfileProfileParams extends PHPW.Api.Lib.DefaultRequestParameters {
        ignoreUser?: boolean | undefined;
    }
    export interface Profile extends PHPW.Api.Bodies.Model {
        columns: PHPW.Table.Profile.Model.Column[];
    }
    export interface SavedIds extends PHPW.Api.Bodies.Model {
        name: string;
        id: number | undefined;
        date: PHPW.ExtendedDateTime | undefined;
        savedIds: number[];
        user: PHPW.Api.Routes.Lib.User.Model.User | undefined;
    }
    export interface SavedSearch extends PHPW.Api.Bodies.Model {
        tableName: string;
        name: string;
        id?: number | undefined;
        sort: Array<{[key: string]: "asc" | "desc"}> | undefined;
        values: undefined | {[key: string]: string};
    }
    export interface SearchResponse extends PHPW.Api.Bodies.Model {
        success: boolean;
        rows: Array<{[key: string]: unknown}>;
    }
    export interface TableArguments extends PHPW.Api.Lib.DefaultRequestArguments {
        name: string;
    }
    export interface TableParameters extends PHPW.Api.Lib.DefaultRequestParameters {
        exportAllColumns?: boolean;
        rowCount: number;
        offset: number;
        sort?: Array<[string, "ASC" | "DESC"]> | undefined;
        special: {[key: string]: undefined | boolean | PHPW.ExtendedDateTime | number | number[] | string};
        filters: {[key: string]: string};
        columns: string[];
        type?: string | undefined;
    }
    export interface TableSearchCountResponse extends PHPW.Api.Responses.DefaultResponse {
        success: boolean;
        count: number;
        rows: Array<{[key: string]: string | number | boolean}>;
    }
}
export namespace PHPW.Api.Routes.Lib.Tax.Model {
    export interface Tax extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        name: string;
        satz: number;
        isDefault: boolean;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        trashNote?: string | undefined;
        country?: PHPW.Api.Routes.ComboboxModel.Country | undefined;
        trashUser?: number | undefined;
    }
    export interface TaxSchema extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        name: string;
        countryId?: number | undefined;
        standardTaxRate: PHPW.Api.Routes.Lib.Setup.Model.Tax;
        reducedTaxRate: PHPW.Api.Routes.Lib.Setup.Model.Tax;
    }
}
export namespace PHPW.Api.Routes.Lib.Textvorlage.Model {
    export interface GetTextvorlageParams extends PHPW.Api.Lib.DefaultRequestParameters {
        silent: boolean;
        name: string;
    }
    export interface TextBlock extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        insertDate: PHPW.ExtendedDateTime | undefined;
        insertUserId: number | undefined;
        lastUpdate: PHPW.ExtendedDateTime | undefined;
        lastUpdateUserId: number | undefined;
        trash: boolean | undefined;
        trashDate: PHPW.ExtendedDateTime | undefined;
        trashUserId: number | undefined;
        trashNote: string | undefined;
        name: string;
        subject: string;
        content: string;
        attachments: PHPW.Api.Routes.Lib.Mail.Model.Send.Attachment[];
        categories: PHPW.Api.Routes.ComboboxModel.TextBlockTag[];
    }
    export interface TextBlockChange extends PHPW.Api.Lib.DefaultBody {
        category: PHPW.Api.Routes.ComboboxModel.TextBlockTag[];
        categoryActive: boolean;
    }
}
export namespace PHPW.Api.Routes.Lib.Ticket.Model {
    export interface Ticket extends PHPW.Api.Responses.DefaultResponse {
        bereich: string | undefined;
        kategorie: string | undefined;
        text: string;
    }
}
export namespace PHPW.Api.Routes.Lib.User.Model {
    export enum PhoneType {
        "snom" = "snom",
        "ami" = "ami",
        "tellink" = "telLink"
    }
    export interface AuthenticationExtensionsClientInputs {
        appid?: string | undefined;
        credProps?: boolean | undefined;
        hmacCreateSecret?: boolean | undefined;
        minPinLength?: boolean | undefined;
    }
    export interface AuthenticationPublicKeyCredential extends PHPW.Api.Routes.Lib.User.Model.PublicKeyCredential {
        response: PHPW.Api.Routes.Lib.User.Model.AuthenticatorResponse;
    }
    export interface AuthenticatorAssertionResponse {
        clientDataJSON: string;
        authenticatorData: string;
        signature: string;
        userHandle: string | null;
    }
    export interface AuthenticatorAttestationResponse {
        clientDataJSON: string;
        attestationObject: string;
        transports: Array<AuthenticatorTransport | "hybrid">;
    }
    export interface AuthenticatorResponse {
        clientDataJSON: string;
    }
    export interface AuthenticatorSelectionCriteria {
        authenticatorAttachment?: AuthenticatorAttachment;
        requireResidentKey?: boolean | undefined;
        residentKey?: ResidentKeyRequirement;
        userVerification?: UserVerificationRequirement;
    }
    export interface CredPropsAuthenticationExtensionsClientOutputs {
        rk: boolean;
    }
    export interface Credential {
        id: string;
        type: string;
    }
    export interface CredentialCreateOptions extends PHPW.Api.Bodies.Model {
        sessionId: string;
        publicKey: PHPW.Api.Routes.Lib.User.Model.PublicKeyCredentialCreationOptions;
    }
    export interface CredentialRequestOptions extends PHPW.Api.Bodies.Model {
        sessionId: string;
        mediation: CredentialMediationRequirement;
        publicKey: PHPW.Api.Routes.Lib.User.Model.PublicKeyCredentialRequestOptions;
    }
    export interface DeleteUserEmailLinkArguments extends PHPW.Api.Lib.Common.ArgumentsId {
        miea_id: number;
    }
    export interface GetTwoFactor extends PHPW.Api.Bodies.Model {
        qrCode: string;
        active: boolean;
    }
    export interface GetUserEmailAddressArguments extends PHPW.Api.Lib.DefaultRequestArguments {
        id: number;
        miea_id: number;
    }
    export interface GlobalStoreUser extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        vorname: string;
        name: string;
        username: string;
        email: string;
        kuerzel: string;
        signature: string;
        phoneTypes: PHPW.Api.Routes.Lib.User.Model.PhoneType[];
    }
    export interface InAccount extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        host: string | undefined;
        username: string | undefined;
        default: boolean;
        deleted: boolean;
    }
    export interface Marker extends PHPW.Api.Lib.DefaultBody {
        aufgabe_unread: number;
        aufgabe_due: number;
        mail_unread: number;
        mail_unworked: number;
        termin_today: number;
        termin_reminder: number;
    }
    export interface Message extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        time: PHPW.ExtendedDateTime;
        type: string;
        color: string;
        creatorId: number | undefined;
        priorityId: number | undefined;
        headline: string;
        message: string;
        aufgabeId: number | undefined;
    }
    export interface OutAccount extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        email: string | undefined;
        name: string | undefined;
        deleted: boolean;
    }
    export interface PostTwoFactor extends PHPW.Api.Bodies.Model {
        active: boolean;
        totp: string | undefined;
    }
    export interface PostUserIdPasswordRequestBody extends PHPW.Api.Lib.DefaultBody {
        oldPw: string;
        newPw: string;
    }
    export interface PostUserLoginAsParams extends PHPW.Api.Lib.DefaultRequestParameters {
        uId: number;
    }
    export interface PostUserLoginRequestBody extends PHPW.Api.Lib.DefaultBody {
        lang?: string | undefined;
        username: string;
        password?: string | undefined;
        totp?: string | undefined;
        profile?: string | undefined;
        cp?: string | undefined;
    }
    export interface PostUserLoginResponseBody extends PHPW.Api.Lib.DefaultBody {
        message: string | undefined;
        result: number | undefined;
        action: string | undefined;
    }
    export interface PostUserResponse extends PHPW.Api.Responses.SaveResponse {
        newPw: string | undefined;
    }
    export interface PostUserWebauthnCheckCredentialsRequest extends PHPW.Api.Bodies.Model {
        publicKey: PublicKeyCredentialRequestOptions;
    }
    export interface PublicKeyCredential extends PHPW.Api.Bodies.Model {
        id: string;
        type: PublicKeyCredentialType;
        rawId: string;
        authenticatorAttachment?: AuthenticatorAttachment | null;
    }
    export interface PublicKeyCredentialCreationOptions {
        attestation?: AttestationConveyancePreference;
        authenticatorSelection?: PHPW.Api.Routes.Lib.User.Model.AuthenticatorSelectionCriteria | undefined;
        challenge: string;
        excludeCredentials: PHPW.Api.Routes.Lib.User.Model.PublicKeyCredentialDescriptor[];
        extensions: PHPW.Api.Routes.Lib.User.Model.AuthenticationExtensionsClientInputs;
        pubKeyCredParams: PHPW.Api.Routes.Lib.User.Model.PublicKeyCredentialParameters[];
        rp: PHPW.Api.Routes.Lib.User.Model.PublicKeyCredentialRpEntity;
        timeout: number | undefined;
        user: PHPW.Api.Routes.Lib.User.Model.PublicKeyCredentialUserEntity;
    }
    export interface PublicKeyCredentialDescriptor {
        id: string;
        transports?: AuthenticatorTransport[];
        type: PublicKeyCredentialType;
    }
    export interface PublicKeyCredentialParameters {
        alg: number;
        type: PublicKeyCredentialType;
    }
    export interface PublicKeyCredentialRequestOptions extends PHPW.Api.Bodies.Model {
        allowCredentials?: PHPW.Api.Routes.Lib.User.Model.PublicKeyCredentialDescriptor[] | undefined;
        challenge: string;
        extensions?: PHPW.Api.Routes.Lib.User.Model.AuthenticationExtensionsClientInputs | undefined;
        rpId?: string | undefined;
        timeout?: number | undefined;
        userVerification: UserVerificationRequirement;
    }
    export interface PublicKeyCredentialRpEntity {
        id: string | undefined;
        name: string;
    }
    export interface PublicKeyCredentialUserEntity {
        displayName: string;
        id: string;
        name: string;
    }
    export interface PublicKeyCredentialWithAssertion extends PHPW.Api.Routes.Lib.User.Model.PublicKeyCredential {
        sessionId: string;
        response: PHPW.Api.Routes.Lib.User.Model.AuthenticatorAssertionResponse;
        clientExtensionResults: PHPW.Api.Routes.Lib.User.Model.SimpleClientExtensionResults;
    }
    export interface PublicKeyCredentialWithAttestation extends PHPW.Api.Routes.Lib.User.Model.PublicKeyCredential {
        sessionId: string;
        response: PHPW.Api.Routes.Lib.User.Model.AuthenticatorAttestationResponse;
        clientExtensionResults: PHPW.Api.Routes.Lib.User.Model.SimpleClientExtensionResults;
    }
    export interface PushSubscription extends PHPW.Api.Bodies.Model {
        endpoint?: string | undefined;
        keys?: Record<string, string>;
    }
    export interface PushSubscriptionKeys {
        p256dh: string | undefined;
        auth: string | undefined;
    }
    export interface SimpleClientExtensionResults {
        appid?: boolean | undefined;
        appidExclude?: boolean | undefined;
        credProps?: PHPW.Api.Routes.Lib.User.Model.CredPropsAuthenticationExtensionsClientOutputs | undefined;
    }
    export interface User extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        insertDate?: PHPW.ExtendedDateTime | undefined;
        trashDate?: PHPW.ExtendedDateTime | undefined;
        username: string;
        name: string;
        vorname: string;
        kuerzel: string;
        group?: number | undefined;
        visibility?: number | undefined;
        expiration?: PHPW.ExtendedDateTime | undefined;
        email: string;
        phone: string;
        mobile: string;
        hash?: string | undefined;
        amiChannel?: string | undefined;
        amiContext?: string | undefined;
        snomIp?: string | undefined;
        authorizationLevel: number;
        externalLoginAllowed?: boolean;
        additionalPermissions: PHPW.Api.Routes.ComboboxModel.Permission[];
        infotext?: string | undefined;
        signature: string;
        imapAccounts: PHPW.Api.Routes.Lib.User.Model.InAccount[];
        outAddresses: PHPW.Api.Routes.Lib.User.Model.OutAccount[];
    }
    export interface UserEmail extends PHPW.Api.Lib.DefaultBody {
        id: number | undefined;
        mailId: number | undefined;
        mailAddress: string;
        name: string;
        sort: number | undefined;
    }
    export interface UserEmailInfo extends PHPW.Api.Bodies.Model {
        imapAccounts: Array<{id: number; text: string; isDefault: boolean; byGlobalPerm: boolean}>;
        outAddresses: Array<{id: number; text: string; name: string}>;
    }
    export interface UserNote extends PHPW.Api.Bodies.Model {
        note: string;
    }
}
export namespace PHPW.Api.Routes.Lib.Users.Model {
    export interface CollectionUser extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        username: string;
        trash: boolean;
        kuerzel: string;
        vorname: string;
        name: string;
        hash: string;
        authorizationLevel: number;
        pbxExtension: string | undefined;
    }
    export interface UserGroups extends PHPW.Api.Bodies.Model {
        groups: PHPW.User.Model.UserGroup[];
    }
}
export namespace PHPW.Article.Model {
    export interface ArticleChange extends PHPW.Api.Bodies.Model {
        categories: PHPW.Api.Routes.ComboboxModel.ArticleCategory[];
        categoriesActive: boolean;
        mwst?: number | undefined;
        mwstActive: boolean;
        einheit?: number | undefined;
        einheitActive: boolean;
        originCountry?: number | undefined;
        originCountryActive: boolean;
        snPflege?: PHPW.Api.Routes.Lib.Article.Lib.EArticleType | undefined;
        snPflegeActive: boolean;
        hersteller?: PHPW.Api.Routes.ComboboxModel.ArticleBrand | undefined;
        herstellerActive: boolean;
        lagerort?: string | undefined;
        lagerortActive: boolean;
        warengruppe?: string | undefined;
        warengruppeActive: boolean;
        skontierbar: boolean;
        skontierbarActive: boolean;
        rabattierbar: boolean;
        rabattierbarActive: boolean;
        liefersperre?: boolean | undefined;
        liefersperreActive: boolean;
        zolltarifnummerActive: boolean;
        zolltarifnummer?: string | undefined;
    }
    export interface ArticleEinkaufspreis extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        aId: number;
        cId: number | undefined;
        start: PHPW.ExtendedDateTime | undefined;
        stop: PHPW.ExtendedDateTime | undefined;
        price: number;
        rabatt: number;
        minAmount: number;
        comment: string;
    }
    export interface InventurArticle extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        articleId?: number | undefined;
        articleNumber?: string | undefined;
        articleManufacturer?: string | undefined;
        articleName?: string | undefined;
        comment?: string | undefined;
        articleType: PHPW.Api.Routes.Lib.Article.Lib.EArticleType;
        elements: PHPW.Article.Model.InventurArticleAmount[];
    }
    export interface InventurArticleAmount {
        id?: number | undefined;
        lagerplatz: PHPW.Api.Routes.ComboboxModel.LagerPlatz;
        lagerPlatzBestandType?: PHPW.Api.Routes.ComboboxModel.LagerPlatzBestandType | undefined;
        soll?: number | undefined;
        ist?: number | undefined;
        snr?: string[] | undefined;
    }
    export interface InventurLagerplatz extends PHPW.Api.Bodies.Model {
        id: number;
        text: string;
        type: PHPW.Entities.Enum.LagerPlatzTyp;
        articles: PHPW.Article.Model.InventurLagerplatzArticles[];
    }
    export interface InventurLagerplatzArticles extends PHPW.Api.Bodies.Model {
        article?: string | undefined;
        article_id: number | undefined;
        articleType: PHPW.Api.Routes.Lib.Article.Lib.EArticleType | undefined;
        soll: number;
        ist: number;
        snr: string[];
        id: number | undefined;
        inventurArticleId: number | undefined;
    }
}
export namespace PHPW.Aufgabe.Model {
    export interface AufgabeCategory {
        id: number;
        text: string;
        trashDate?: undefined | PHPW.ExtendedDateTime;
    }
    export interface AufgabeChange extends PHPW.Api.Bodies.Model {
        category: string[];
        categoryActive: boolean;
        typeId?: number | undefined;
        typeActive: boolean;
        stateId?: number | undefined;
        stateActive: boolean;
        priorityId?: number | undefined;
        priorityActive: boolean;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        contactActive: boolean;
        projectId?: number | undefined;
        projectActive: boolean;
        planDate?: PHPW.ExtendedDateTime | undefined;
        planDateActive: boolean;
        deadline?: PHPW.ExtendedDateTime | undefined;
        deadlineActive: boolean;
        userData: PHPW.Api.Routes.Lib.Aufgabe.Model.AufgabeUserLink[];
    }
    export interface AufgabeLink {
        description: string;
        extendedDescription: string;
        destinationType: string;
        destinationId: number;
    }
    export interface AufgabeTag {
        id: number;
        text: string;
    }
    export interface AufgabeTemplate extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        version: string;
        name: string;
        data: PHPW.Api.Routes.Lib.Aufgabe.Model.AufgabeTemplateToAufgabeV1;
    }
}
export namespace PHPW.Booking.EuroFXRefService.Model {
    export interface ExchangeRateResult extends PHPW.Api.Bodies.Model {
        time: string;
        rates: PHPW.Booking.EuroFXRefService.Model.ExchangeRates;
    }
    export interface ExchangeRates {
        USD: number;
        JPY: number;
        BGN: number;
        CZK: number;
        DKK: number;
        GBP: number;
        HUF: number;
        PLN: number;
        RON: number;
        SEK: number;
        CHF: number;
        ISK: number;
        NOK: number;
        HRK: number;
        RUB: number;
        TRY: number;
        AUD: number;
        BRL: number;
        CAD: number;
        CNY: number;
        HKD: number;
        IDR: number;
        ILS: number;
        INR: number;
        KRW: number;
        MXN: number;
        MYR: number;
        NZD: number;
        PHP: number;
        SGD: number;
        THB: number;
        ZAR: number;
    }
}
export namespace PHPW.Booking.Kontenplan.Model {
    export interface InitialKonto extends PHPW.Api.Bodies.Model {
        funktionstyp: string | undefined;
        funktion: string | undefined;
        zusatzfunktion: string | undefined;
        name: string;
        abschlusszweck: string | undefined;
        category: string | undefined;
        subcategory: string | undefined;
        bilanzPosten: string | undefined;
        guvPosten: string | undefined;
        individual: boolean;
        number: number;
    }
}
export namespace PHPW.Booking.Model {
    export interface BookingTaxkey {
        number: number;
        name: string;
        gruppe: number;
        description: string;
        steuersatz: number | undefined;
        oss: boolean;
        zm: undefined | "D" | "L" | "S";
        ustva: Array<{bemessungsgrundlage?: number; vorsteuer?: number; steuer?: number}>;
        funktion: {[key: number]: string};
    }
    export interface DatevImportHeaderInfo extends PHPW.Api.Bodies.Model {
        kennzeichen: string;
        versionsnummer: string;
        formatkategorie: string;
        formatname: string;
        formatversion: string;
        sachkontenlaenge: string;
        buchungstyp: string;
        rechnungslegungszweck: string | undefined;
        wkz: string | undefined;
        sachkontenrahmen: string | undefined;
        erzeugtAm: PHPW.ExtendedDateTime | undefined;
        exportiertVon: string;
        importiertVon: string;
        beraternummer: string;
        mandantennummer: string;
        wjBeginn: PHPW.ExtendedDateTime;
        datumVon: PHPW.ExtendedDateTime | undefined;
        datumBis: PHPW.ExtendedDateTime | undefined;
        bezeichnung: string;
        diktatkuerzel: string | undefined;
        festschreibung: string | undefined;
        derivatskennzeichen: string | undefined;
    }
    export interface DatevImportInfo extends PHPW.Api.Bodies.Model {
        count: number;
        newCount: number;
        updateCount: number;
        searchFilter: string | undefined;
        missingAccounts: number[];
    }
}
export namespace PHPW.Camt.DTO {
    export enum BookingType {
        "Incoming" = "INC",
        "Outgoing" = "OUT",
        "Unknown" = "UNK"
    }
    export enum RowType {
        "Incoming" = "INC",
        "Outgoing" = "OUT"
    }
    export interface BookingDTO {
        id: number;
        text: string;
        amount: number;
        documentNumber: number;
    }
    export interface ConnectRow extends PHPW.Api.Bodies.Model {
        rowType: PHPW.Camt.DTO.RowType | undefined;
        invoiceId: number | undefined;
        contact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        invoiceDate: PHPW.ExtendedDateTime | undefined;
        invoiceNumber: string | undefined;
        invoiceAmount: number | undefined;
        invoiceAmountSkonto: number | undefined;
        bookingId: number | undefined;
        bookingDate: PHPW.ExtendedDateTime | undefined;
        bookingNumber: string | undefined;
        bookingAmount: number | undefined;
    }
    export interface InvoiceDTO {
        id: number;
        number: string;
        amount: number;
    }
}
export namespace PHPW.Camt.Enum {
    export enum TypeDomain {
        "ACMT" = "ACMT",
        "CAMT" = "CAMT",
        "LDAS" = "LDAS",
        "PMNT" = "PMNT",
        "XTND" = "XTND"
    }
    export enum TypeFamily {
        "MCOP" = "MCOP",
        "MDOP" = "MDOP",
        "OPCL" = "OPCL",
        "CNTR" = "CNTR",
        "CCRD" = "CCRD",
        "ICDT" = "ICDT",
        "ICCN" = "ICCN",
        "ICHQ" = "ICHQ",
        "IDDT" = "IDDT",
        "RCDT" = "RCDT",
        "RCCN" = "RCCN",
        "RCHQ" = "RCHQ",
        "RRCT" = "RRCT",
        "RDDT" = "RDDT",
        "NTAV" = "NTAV",
        "FTLN" = "FTLN"
    }
    export enum TypeSubFamily {
        "OTHR" = "OTHR",
        "CHRG" = "CHRG",
        "ACCC" = "ACCC",
        "BCDP" = "BCDP",
        "CDPT" = "CDPT",
        "BCWD" = "BCWD",
        "CWDL" = "CWDL",
        "BOOK" = "BOOK",
        "ICCT" = "ICCT",
        "ESCT" = "ESCT",
        "RPCR" = "RPCR",
        "SDVA" = "SDVA",
        "STDO" = "STDO",
        "XBCT" = "XBCT",
        "CCHQ" = "CCHQ",
        "BBDD" = "BBDD",
        "ESDD" = "ESDD",
        "FEES" = "FEES",
        "RCDD" = "RCDD",
        "UPDD" = "UPDD",
        "URCQ" = "URCQ",
        "UPCQ" = "UPCQ",
        "RRTN" = "RRTN",
        "NTAV" = "NTAV",
        "COMM" = "COMM"
    }
}
export namespace PHPW.Config.Model {
    export interface Color {
        background: string;
        contrastRatio: number;
    }
    export interface Colors extends PHPW.Api.Bodies.Model {
        primary: PHPW.Config.Model.Color;
        secondary: PHPW.Config.Model.Color;
        info: PHPW.Config.Model.Color;
        success: PHPW.Config.Model.Color;
        warning: PHPW.Config.Model.Color;
        question: PHPW.Config.Model.Color;
        error: PHPW.Config.Model.Color;
        isDark: boolean | undefined;
    }
    export interface DeviceConfig extends PHPW.Api.Bodies.Model {
        enableMultiLine: boolean;
        openTabInBackground: boolean;
        sidebarDefaultClose: boolean;
        showDashboard: boolean;
        openOnSingleResult: boolean;
        smallSidebar: boolean;
        smallIndexColumn: boolean;
        openYellowboxes: boolean;
        singleColumn: boolean;
    }
    export interface UserConfig extends PHPW.Api.Bodies.Model {
        colorProfile: string;
        changelogLastRead: string | undefined;
        onlyNewestProcesses: boolean;
        showTextMail: boolean;
    }
}
export namespace PHPW.Data.Io.Import.Article.Model {
    export interface ImportSettings {
        contact: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        buyingPriceDiscount: number;
        addNewArticle: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importCategories: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importBrand: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importZtn: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importExterneId: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importHaendlerBestellnummer: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importDescription: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importBarcode: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importBuyingPrice: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importUVP: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importArtikelnummer: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importLangText: PHPW.Data.Io.Import.Common.Model.ImportValue;
        importBeschreibung: PHPW.Data.Io.Import.Common.Model.ImportValue;
        generateSellingPrice: PHPW.Data.Io.Import.Common.Model.ImportValue;
    }
}
export namespace PHPW.Data.Io.Import.Common.Model {
    export enum ImportValue {
        "no" = 0,
        "yes" = 1,
        "yesEmpty" = 2
    }
    export interface CheckResult extends PHPW.Api.Bodies.Model {
        ok: boolean;
    }
    export interface Result extends PHPW.Api.Bodies.Model {
        count: number;
        newCount: number;
        updateCount: number;
    }
}
export namespace PHPW.Data.Io.Import.Contact.Model {
    export interface ImportSettings {
        addNewContact: PHPW.Data.Io.Import.Common.Model.ImportValue;
    }
}
export namespace PHPW.DataNorm.v4.Model.Record {
    export interface Article extends PHPW.DataNorm.v4.Model.Record.Record {
        recordMark: string;
        processingFlag: string;
        articlenumber: string;
        textFlag: string;
        shortDescription1: string | undefined;
        shortDescription2: string;
        priceIndicator: string;
        priceUnitAmount: string;
        priceUnit: string | undefined;
        price: number;
        discountGroup: string | undefined;
        mainProductGroup: string | undefined;
        longtextblocknumber: string | undefined;
    }
    export interface DiscountGroups extends PHPW.DataNorm.v4.Model.Record.Record {
        recordMark: string;
        dummy: string;
        discountGroup: string;
        discountMark: string;
        discount: string;
        groupDescription: string;
    }
    export interface PriceChange extends PHPW.DataNorm.v4.Model.Record.Record {
        recordMark: string;
        processingFlag: string;
        articlenumber1: string;
        priceFlag1: string;
        price1: string;
        discountFlag1a: string;
        discount1a: string;
        discountFlag1b: string;
        discount1b: string;
        discountFlag1c: string;
        discount1c: string;
        articlenumber2: string;
        priceFlag2: string;
        price2: string;
        discountFlag2a: string;
        discount2a: string;
        discountFlag2b: string;
        discount2b: string;
        discountFlag2c: string;
        discount2c: string;
        articlenumber3: string;
        priceFlag3: string;
        price3: string;
        discountFlag3a: string;
        discount3a: string;
        discountFlag3b: string;
        discount3b: string;
        discountFlag3c: string;
        discount3c: string;
        priceFlag: string;
        priceAmount: string;
        price: string;
        date: string;
        discountFlag1: string;
        discount1: string;
        discountFlag2: string;
        discount2: string;
        discountFlag3: string;
        discount3: string;
        discountGroup: string;
    }
    export interface Record {
        recordMark: string;
    }
    export interface Surcharge extends PHPW.DataNorm.v4.Model.Record.Record {
        recordMark: string;
        processingFlag: string;
        articlenumber: string;
        setnumber: string;
        workingFlag: string;
        basicIndicator: string;
        basicDescription: string;
        surchargeMark: string;
        surcharge: string;
        amountFrom: string;
        amountTo: string;
    }
}
export namespace PHPW.DataNorm.v4.Model.Work {
    export interface Article {
        number: string;
        deleted: undefined | boolean | PHPW.ExtendedDateTime;
        replacementNumber: undefined | string;
        description: undefined | string;
        text: undefined | PHPW.DataNorm.v4.Model.Work.Text;
        gTIN: undefined | string;
        altNumber: undefined | string;
        priceUnit: undefined | string;
        priceInfo: undefined | PHPW.DataNorm.v4.Model.Work.PriceChange;
        priceIndicator: undefined | string;
        priceUnitAmount: undefined | number;
        price: undefined | number;
    }
    export interface PriceChange {
        flag: string;
        amount: undefined | number;
        price: undefined | number;
        discountArray: PHPW.DataNorm.v4.Model.Record.DiscountGroups[];
        netPrice: undefined | number;
        date: undefined | PHPW.ExtendedDateTime | boolean;
    }
    export interface Text {
        number: string;
    }
}
export namespace PHPW.DataNorm.v5.Model.Record {
    export interface Article extends PHPW.DataNorm.v5.Model.Record.Record {
        recordMark: string;
        processingFlag: string;
        articlenumber: string;
        shortDescription1: string | undefined;
        shortDescription2: string | undefined;
        priceUnit: string;
        priceIndicator: string;
        priceUnitAmount: string;
        price: string;
        discountGroup: string | undefined;
        mainProductGroup: string | undefined;
        productGroup: string | undefined;
        matchcode: string | undefined;
        creatorAltArticlenumber: string | undefined;
        altArticlenumber: string | undefined;
        creatorManufacturerNumber: string | undefined;
        manufacturerNumber: string | undefined;
        type: string | undefined;
        gTIN: string | undefined;
        graphicNumber: string | undefined;
        orderAmount: number;
        cataloguePage: string | undefined;
        textFlag: string;
        longtextblocknumber: string | undefined;
        costIndicator: string;
        stockIndicator: string | undefined;
        creatorReferencenumber: string | undefined;
        referencenumber: string | undefined;
        vatIndicator: string;
    }
    export interface DiscountGroups extends PHPW.DataNorm.v5.Model.Record.Record {
        recordMark: string;
        discountGroup: string;
        discountMark: string;
        discount: string;
        groupDescription: string;
    }
    export interface PriceChange extends PHPW.DataNorm.v5.Model.Record.Record {
        recordMark: string;
        articlenumber: string;
        priceFlag: string;
        priceAmount: string;
        price: string;
        discountGroup: string;
        discountFlag1: string;
        discount1: string;
        discountFlag2: string;
        discount2: string;
        discountFlag3: string;
        discount3: string;
        date: string;
    }
    export interface Record {
        recordMark: string;
    }
    export interface Surcharge extends PHPW.DataNorm.v5.Model.Record.Record {
        recordMark: string;
        processingFlag: string;
        articlenumber: string;
        linenumber: string;
        workingFlag: number;
        additionalText: string;
        displayText: string;
        surchargeIndicator: string;
        surchargeMark: string;
        priceIndicator: string;
        priceUnitAmount: string;
        amount: string;
        sortIndicator: string;
        basicIndicator: string;
        amountFrom: string;
        amountTo: string;
    }
}
export namespace PHPW.Disposition.Model {
    export interface StatusGrouping extends PHPW.Api.Bodies.Model {
        category: string;
        anzahl: number;
        corr: number | undefined;
    }
}
export namespace PHPW.Document.Enum {
    export enum WatermarkType {
        "none" = "none",
        "preview" = "preview",
        "cancelled" = "cancelled"
    }
}
export namespace PHPW.Document.Html.Model {
    export enum DataBaseType {
        "mahnung" = "mahnung",
        "avis" = "avis",
        "letter" = "letter",
        "process" = "process",
        "custom" = "custom"
    }
    export enum TableRowType {
        "vat" = "vat",
        "avis" = "avis",
        "total" = "total",
        "mahnung" = "mahnung",
        "final" = "final",
        "finalSum" = "finalSum",
        "skonto" = "skonto",
        "toPay" = "toPay",
        "position" = "position",
        "alternative" = "alternative",
        "doubleSeparator" = "doubleSeparator",
        "optional" = "optional",
        "pagebreak" = "pagebreak",
        "separator" = "separator",
        "titleStart" = "titleStart",
        "titleEnd" = "titleEnd",
        "setStart" = "losStart",
        "setEnd" = "losEnd",
        "collectionStart" = "collectionStart",
        "collectionEnd" = "collectionEnd",
        "subtotal" = "subtotal",
        "text" = "text",
        "change" = "change",
        "extraCost" = "extraCost",
        "fee" = "fee",
        "netto" = "netto",
        "rabatt" = "rabatt",
        "title" = "title"
    }
    export interface Address {
        oneline: string;
        firma1: string;
        firma2: string;
        firma3: string;
        strasse: string;
        plz: string;
        ort: string;
        land: string;
    }
    export interface AvisData extends PHPW.Document.Html.Model.Data {
        address: PHPW.Document.Html.Model.Address;
        extras: PHPW.Document.Html.Model.Extras;
        type: PHPW.Document.Html.Model.DataBaseType.avis;
        rows: Array<AvisRowtypeAvis | AvisRowtypeTotal>;
        pretext?: string | undefined;
    }
    export interface AvisRowtypeAvis extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.avis;
        date?: PHPW.ExtendedDateTime | undefined;
        type: string;
        number: string;
        duedate?: PHPW.ExtendedDateTime | undefined;
        betrag: number;
        skontoBetrag: number;
        skontoDate?: PHPW.ExtendedDateTime | undefined;
        skonto: number;
    }
    export interface AvisRowtypeTotal extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.total;
        gesamtbetrag: number;
        skontoGesamtbetrag: number;
        skontierterGesamtbetrag: number;
    }
    export interface Data {
        type: PHPW.Document.Html.Model.DataBaseType;
        category?: PHPW.Process.Model.ProcessTypeCategory | undefined;
        settings?: PHPW.Document.Html.Model.ProcessSettings | undefined;
        watermark: PHPW.Document.Enum.WatermarkType;
        pretext?: string | undefined;
        posttext?: string | undefined;
        senderHeadline?: string | undefined;
        subject?: string | undefined;
        barcode?: string | undefined;
        tssQrCode?: string | undefined;
    }
    export interface Extras {
        customerNr?: string | undefined;
        documentNumber?: string | undefined;
        date?: PHPW.ExtendedDateTime | undefined;
        deliveryDate?: PHPW.ExtendedDateTime | string | undefined;
        bearbeiter?: string | undefined;
        besteller?: string | undefined;
        type?: string | undefined;
        ustid?: string | undefined;
        ownUstid?: string | undefined;
        contactExtNr?: string | undefined;
        contactExtra1?: string | undefined;
        contactExtra2?: string | undefined;
        contactExtra3?: string | undefined;
        contactExtra4?: string | undefined;
        contactExtra5?: string | undefined;
        extraDate1?: PHPW.ExtendedDateTime | undefined;
        extraDate2?: PHPW.ExtendedDateTime | undefined;
        extraDateTime1?: PHPW.ExtendedDateTime | undefined;
        extraDateTime2?: PHPW.ExtendedDateTime | undefined;
        extraNumber1?: number | undefined;
        extraNumber2?: number | undefined;
        extraText1?: string | undefined;
        extraText2?: string | undefined;
        extraText3?: string | undefined;
        extraText4?: string | undefined;
        contactTelefon?: string | undefined;
        contactMobile?: string | undefined;
        orderRef?: string | undefined;
        offerRef?: string | undefined;
        confirmationRef?: string | undefined;
        deliveryRef?: string | undefined;
        ref?: string | undefined;
        kommission?: string | undefined;
        projectReference?: string | undefined;
        contractReference?: string | undefined;
        purchaseOrderReference?: string | undefined;
        tenderOrLotReference?: string | undefined;
    }
    export interface LetterData extends PHPW.Document.Html.Model.Data {
        address: PHPW.Document.Html.Model.Address;
        extras: PHPW.Document.Html.Model.Extras;
        type: PHPW.Document.Html.Model.DataBaseType.letter;
        text: string;
    }
    export interface MahnungData extends PHPW.Document.Html.Model.Data {
        address: PHPW.Document.Html.Model.Address;
        extras: PHPW.Document.Html.Model.Extras;
        type: PHPW.Document.Html.Model.DataBaseType.mahnung;
        rows: PHPW.Document.Html.Model.MahnungRowtypeMahnung[];
        sum: Array<
            | ProcessSumChange
            | ProcessSumExtraCost
            | ProcessSumFee
            | ProcessSumNetto
            | ProcessSumRabatt
            | ProcessSumSkonto
            | ProcessSumTotal
            | ProcessSumVat
        >;
        interest: number;
    }
    export interface MahnungRowtypeMahnung extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.mahnung;
        date?: PHPW.ExtendedDateTime | undefined;
        type: string;
        number: string;
        duedate?: PHPW.ExtendedDateTime | undefined;
        betrag: number;
        rest: number;
        interest: number;
    }
    export interface ProcessData extends PHPW.Document.Html.Model.Data {
        address: PHPW.Document.Html.Model.Address;
        extras: PHPW.Document.Html.Model.Extras;
        type: PHPW.Document.Html.Model.DataBaseType.process;
        deliveryAddress?: PHPW.Document.Html.Model.Address | undefined;
        positions: Array<
            | ProcessPosition
            | ProcessPositionCollection
            | ProcessPositionCollectionEnd
            | ProcessPositionDoubleSeparator
            | ProcessPositionPagebreak
            | ProcessPositionRabatt
            | ProcessPositionSeparator
            | ProcessPositionSetEnd
            | ProcessPositionSubtotal
            | ProcessPositionText
            | ProcessPositionTitleEnd
            | ProcessPositionTitleStart
        >;
        sum: Array<
            | ProcessSumChange
            | ProcessSumExtraCost
            | ProcessSumFee
            | ProcessSumNetto
            | ProcessSumRabatt
            | ProcessSumSkonto
            | ProcessSumTotal
            | ProcessSumVat
        >;
        prepaymentSum: Array<
            | ProcessSumChange
            | ProcessSumExtraCost
            | ProcessSumFee
            | ProcessSumNetto
            | ProcessSumRabatt
            | ProcessSumSkonto
            | ProcessSumTotal
            | ProcessSumVat
        >;
        rma?: PHPW.Document.Html.Model.ProcessRma | undefined;
        final?:
            | undefined
            | Array<ProcessFinalFinal | ProcessFinalSkonto | ProcessFinalToPay | ProcessFinalVat | ProcessFinalSum>;
        titles: PHPW.Document.Html.Model.ProcessTitleTitle[];
    }
    export interface ProcessFinalFinal extends PHPW.Document.Html.Model.ProcessFinal {
        rowtype: PHPW.Document.Html.Model.TableRowType.final;
        number: string;
        netto: number;
        ust: number;
        brutto: number;
        mwst: number;
        outstanding: number;
        showOutstanding: boolean;
    }
    export interface ProcessFinalSkonto extends PHPW.Document.Html.Model.ProcessFinal {
        rowtype: PHPW.Document.Html.Model.TableRowType.skonto;
        value: number;
        satz: number;
        baseValue: number;
        days: number;
        notAll: boolean;
    }
    export interface ProcessFinalSum extends PHPW.Document.Html.Model.ProcessFinal {
        rowtype: PHPW.Document.Html.Model.TableRowType.finalSum;
        netto: number;
        ust: number;
        brutto: number;
        outstanding: number;
    }
    export interface ProcessFinalToPay extends PHPW.Document.Html.Model.ProcessFinal {
        rowtype: PHPW.Document.Html.Model.TableRowType.toPay;
        toPay: number;
    }
    export interface ProcessFinalVat extends PHPW.Document.Html.Model.ProcessFinal {
        rowtype: PHPW.Document.Html.Model.TableRowType.vat;
        finalMwst: number;
        mwst: number;
    }
    export interface ProcessPosition extends PHPW.Document.Html.Model.TableRow {
        pos: string;
        anz: number;
        me: string;
        number: string;
        customerNumber?: string | undefined;
        title: string;
        desc: string;
        serials?: string | undefined;
        price: number;
        sum: number;
        tax: number;
        visibleDiscount?: PHPW.Document.Html.Model.ProcessPositionRabatt | undefined;
        hiddenDiscounts: PHPW.Document.Html.Model.ProcessPositionRabatt[];
        images: string[];
    }
    export interface ProcessPositionAlternative extends PHPW.Document.Html.Model.ProcessPosition {
        rowtype: PHPW.Document.Html.Model.TableRowType.alternative;
        images: string[];
    }
    export interface ProcessPositionArticle extends PHPW.Document.Html.Model.ProcessPosition {
        rowtype: PHPW.Document.Html.Model.TableRowType.position;
        images: string[];
    }
    export interface ProcessPositionCollection extends PHPW.Document.Html.Model.ProcessPosition {
        rowtype: PHPW.Document.Html.Model.TableRowType.collectionStart;
        parts: Array<
            | ProcessPositionAlternative
            | ProcessPositionArticle
            | ProcessPositionCollection
            | ProcessPositionCollectionEnd
            | ProcessPositionDoubleSeparator
            | ProcessPositionOptional
            | ProcessPositionPagebreak
            | ProcessPositionRabatt
            | ProcessPositionSeparator
            | ProcessPositionSet
            | ProcessPositionSetEnd
            | ProcessPositionSubtotal
            | ProcessPositionText
            | ProcessPositionTitleEnd
            | ProcessPositionTitleStart
        >;
    }
    export interface ProcessPositionCollectionEnd extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.collectionEnd;
    }
    export interface ProcessPositionDoubleSeparator extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.doubleSeparator;
    }
    export interface ProcessPositionOptional extends PHPW.Document.Html.Model.ProcessPosition {
        rowtype: PHPW.Document.Html.Model.TableRowType.optional;
        images: string[];
    }
    export interface ProcessPositionPagebreak extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.pagebreak;
    }
    export interface ProcessPositionRabatt extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.rabatt;
        count: number;
        name: string;
        tax: number;
        baseValue: number;
        value: number;
    }
    export interface ProcessPositionSeparator extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.separator;
    }
    export interface ProcessPositionSet extends PHPW.Document.Html.Model.ProcessPosition {
        rowtype: PHPW.Document.Html.Model.TableRowType.setStart;
        parts: PHPW.Document.Html.Model.ProcessPosition[];
    }
    export interface ProcessPositionSetEnd extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.setEnd;
    }
    export interface ProcessPositionSubtotal extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.subtotal;
    }
    export interface ProcessPositionText extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.text;
        title: string;
        desc: string;
    }
    export interface ProcessPositionTitleEnd extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.titleEnd;
        title: string;
        price: number;
        pos: string;
        sum: number;
        level: number;
    }
    export interface ProcessPositionTitleStart extends PHPW.Document.Html.Model.TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType.titleStart;
        pos: string;
        title: string;
        price: number;
        sum: number;
        level: number;
    }
    export interface ProcessRma {
        number?: string | undefined;
        extRepNr?: string | undefined;
        geraet?: string | undefined;
        snr?: string | undefined;
        contents?: string | undefined;
        error?: string | undefined;
        ownError?: string | undefined;
    }
    export interface ProcessSettings {
        locale: string;
        currency: string;
        showPriceColumn: boolean;
        showLineTotalColumn: boolean;
        showTaxColumn: boolean;
        showRmaHeader: boolean;
        showRmaFooter: boolean;
        showDiscountColumn: boolean;
        show0AmountX: boolean;
        showPriceSumsOptAlt: boolean;
        imageColumnCount: number;
    }
    export interface ProcessSum extends PHPW.Document.Html.Model.TableRow {
        value: number;
    }
    export interface ProcessSumChange extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.change;
        received: number;
    }
    export interface ProcessSumExtraCost extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.extraCost;
    }
    export interface ProcessSumFee extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.fee;
        name: string;
    }
    export interface ProcessSumNetto extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.netto;
        satz: number;
    }
    export interface ProcessSumRabatt extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.rabatt;
        satz: number;
        valuePerTax: {[key: string]: number};
    }
    export interface ProcessSumSkonto extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.skonto;
        satz: number;
        baseValue: number;
        days: number;
        notAll: boolean;
    }
    export interface ProcessSumTotal extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.total;
    }
    export interface ProcessSumVat extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.vat;
        baseValue: number;
        satz: number;
    }
    export interface ProcessTitleTitle extends PHPW.Document.Html.Model.ProcessSum {
        rowtype: PHPW.Document.Html.Model.TableRowType.title;
        name: string;
        pos: string;
        level: number;
    }
    export interface TableData extends PHPW.Document.Html.Model.Data {
        type: PHPW.Document.Html.Model.DataBaseType.custom;
        html: string;
    }
    export interface TableRow {
        rowtype: PHPW.Document.Html.Model.TableRowType;
    }
    export type ProcessFinal = PHPW.Document.Html.Model.TableRow;
}
export namespace PHPW.Document.Model {
    export interface ProcessSettings {
        showPrices: boolean;
        showPriceSums: boolean;
        showDiscountColumn: boolean;
        ignoredCategories: PHPW.Api.Routes.ComboboxModel.ArticleCategory[];
        showBrand: boolean;
        showDescription: boolean;
        showPictures: boolean;
        showSno: boolean;
        showWeight: boolean;
        showDiscount: boolean;
        showParentPositionLink: boolean;
        showSubPositions: boolean;
        showZtn: boolean;
        showOrigin: boolean;
        showShopLink: boolean;
        showShippingPlan: boolean;
        showExternArticlenumber: boolean;
        showCustomerArticlenumber: boolean;
        showArticlenumber: boolean;
        show0AmountX: boolean;
        showPriceSumsOptAlt: boolean;
        showDispoUnit: boolean;
        showPricePerDispoUnit: boolean;
        countByType: boolean;
        globalDispoTimes: boolean;
        showBarcode: boolean;
        orderRef: boolean;
        offerRef: boolean;
        deliveryRef: boolean;
        confirmRef: boolean;
        ref: boolean;
        brutto?: boolean | undefined;
        showRma: boolean;
        eRechnungType?: PHPW.Entities.Enum.ERechnungType | undefined;
        footerPayment: boolean;
        footerSum: boolean;
        footerDeliveryAddress: boolean;
        footerBank: boolean;
        footerAgreement: boolean;
        footerRma: boolean;
        language: PHPW.Entities.Enum.ArticleLanguage;
    }
}
export namespace PHPW.Entities.Enum {
    export enum ArticleLanguage {
        "DE" = "de",
        "EN" = "en"
    }
    export enum ArticleSetType {
        "Default" = 0,
        "OwnPrice" = 1,
        "DerivedPrice" = 2
    }
    export enum AufgabeCommentTag {
        "Invisible" = 0,
        "Default" = 1,
        "Important" = 2,
        "Error" = 3,
        "Solution" = 4
    }
    export enum AufgabeCommentType {
        "Comment" = 0,
        "Auto" = 1,
        "Ccs" = 2,
        "CallNote" = 3
    }
    export enum BankStatementEntryState {
        "UNCHECKED" = 1,
        "PARTIALLY_CONFIDENT" = 2,
        "MATCHED" = 3
    }
    export enum BusinessRelation {
        "B2B" = 0,
        "B2C" = 1,
        "B2A" = 2
    }
    export enum ContactAddressType {
        "No" = 0,
        "Yes" = 1,
        "Primary" = 2
    }
    export enum ContactMailAddressType {
        "unknown" = -1,
        "default" = 1,
        "billing" = 2,
        "newsletter" = 3
    }
    export enum DemandState {
        "OPEN" = 1,
        "APPROVED" = 2,
        "DECLINED" = 3
    }
    export enum DispoBillingType {
        "Day" = 1,
        "TenHours" = 2
    }
    export enum DispoUserStatusType {
        "Default" = 0,
        "HolidayRequest" = 1,
        "HolidayAuthorised" = 2
    }
    export enum ERechnungType {
        "none" = "none",
        "pdf_cii_zf_xrechnung" = "pdf_cii_zf_xrechnung",
        "pdf_cii_zf_en16931" = "pdf_cii_zf_en16931",
        "xml_ubl_xrechnung" = "xml_ubl_xrechnung"
    }
    export enum FulfillmentState {
        "ignore" = 0,
        "none" = 1,
        "partial" = 2,
        "complete" = 3,
        "overGood" = 4,
        "overBad" = 6,
        "error" = 5
    }
    export enum LagerPlatzTyp {
        "Undefiniert" = 0,
        "Intern" = 1,
        "Extern" = 2
    }
    export enum LoginDecision {
        "Error" = "error",
        "TooManyTries" = "tooManyTries",
        "UserUnknown" = "userUnknown",
        "ExternalDenied" = "externalDenied",
        "PasswordIncorrect" = "passwordIncorrect",
        "Ok" = "ok"
    }
    export enum MailImapAccountEncryption {
        "tls" = "tls",
        "ssl" = "ssl"
    }
    export enum MailStatus {
        "UNREAD" = 1,
        "REMINDER" = 2,
        "COMPLAINT" = 3,
        "PRIORITY" = 4,
        "DONE" = 5,
        "UNWORKED" = 6,
        "SENT" = 7,
        "SPAM" = 8,
        "NEWSLETTER" = 9
    }
    export enum ParcelApi {
        "ShipCloud" = "ShipCloud",
        "LetMeShip" = "LetMeShip",
        "SendCloud" = "SendCloud",
        "GlsWeb" = "GlsWeb",
        "Dpd" = "Dpd",
        "Dhl" = "Dhl"
    }
    export enum PhoneNumberType {
        "unknown" = -1,
        "phone1" = 1,
        "phone2" = 2,
        "phone3" = 3,
        "mobile" = 4,
        "fax" = 5
    }
    export enum PriceMode {
        "GLOBAL" = "global",
        "INDIVIDUAL" = "individual"
    }
    export enum ProcessCategory {
        "Entwurf" = 0,
        "Druckfertig" = 1,
        "Barcodejob" = 2,
        "Finalized" = 3
    }
    export enum ProcessPositionShippingDateType {
        "day" = "day",
        "week" = "week",
        "month" = "month"
    }
    export enum ProcessPositionShippingType {
        "delivery" = "delivery",
        "pickup" = "pickup"
    }
    export enum ProcessTaxBehaviour {
        "Default" = 0,
        "EG_Lieferung" = 1,
        "EG_Leistung" = 7,
        "Drittland_Lieferung" = 4,
        "Drittland_Leistung" = 5,
        "Bauleistung" = 2,
        "Seeschiffe" = 6,
        "Misc" = 3,
        "Dreiecksgeschaeft" = 8
    }
    export enum ProcessTemplateType {
        "Html_TcPdf" = 0,
        "Odt_SingleFile" = 1,
        "Odt_MultiFile" = 2,
        "Html_Puppeteer" = 3,
        "Ofsc_ReceiptLine" = 4
    }
    export enum StockAmountTypeCategory {
        "IS_AVAILABLE" = "available",
        "IS_AVAILABLE_DISPO" = "dispoAvailable",
        "IS_SOLD" = "sold",
        "IS_RENTED" = "rented",
        "IS_IN_PRODUCTION" = "inProduction",
        "IS_ORDERED" = "ordered",
        "IS_DIRECT" = "directShipment",
        "IS_DEMAND" = "demand",
        "IS_INSTALLED" = "installed",
        "IS_IN_MAINTENANCE" = "inMaintenance",
        "IS_IN_MAINTENANCE_DISPO" = "dispoInMaintenance"
    }
}
export namespace PHPW.Lager.Model {
    export interface ChangeEntry {
        name: string;
        id?: number | undefined;
        orgValue?: number | undefined;
        value?: number | undefined;
    }
    export interface StockMove extends PHPW.Api.Bodies.Model {
        articleId: number;
        sourcePlace?: PHPW.Api.Routes.ComboboxModel.LagerPlatz;
        sourceType?: PHPW.Api.Routes.ComboboxModel.LagerPlatzBestandType;
        targetPlace?: PHPW.Api.Routes.ComboboxModel.LagerPlatz;
        targetType?: PHPW.Api.Routes.ComboboxModel.LagerPlatzBestandType;
        comment?: string | undefined;
        moveAmount?: number | undefined;
        moveSerials?: undefined | PHPW.Api.Routes.ComboboxModel.ArticleSerial[];
        moveBatches?: undefined | PHPW.Api.Routes.ComboboxModel.ArticleCharge[];
    }
}
export namespace PHPW.Mahnung.Model {
    export interface Result extends PHPW.Api.Bodies.Model {
        documentHash: string | undefined;
    }
}
export namespace PHPW.Mail.Model {
    export interface MailAccess extends PHPW.Api.Responses.DefaultResponse {
        id: number | undefined;
        timestamp: PHPW.ExtendedDateTime | undefined;
        info: string;
        userId: number | undefined;
    }
    export interface MailTemplate extends PHPW.Api.Responses.DefaultResponse {
        name: string;
        content: string;
    }
    export interface TestImapResult {
        sslOk: boolean;
        sslErrorInfo: string;
        tlsOk: boolean;
        tlsErrorInfo: string;
    }
    export interface TestSmtpResult {
        sslOk: boolean;
        sslErrorInfo: string;
        tlsOk: boolean;
        tlsErrorInfo: string;
    }
}
export namespace PHPW.Mail.Model.MailParser {
    export interface Attachment {
        contentType: string;
        cid: string | undefined;
        related: boolean;
        checksum: string;
        size: number;
        content: string;
        filename: string;
    }
    export interface HeaderLine {
        key: string;
        line: string;
    }
    export interface Mail {
        attachments: PHPW.Mail.Model.MailParser.Attachment[];
        headerLines: PHPW.Mail.Model.MailParser.HeaderLine[];
        message: string | undefined;
        subject: string | undefined;
        references: string[];
        date: PHPW.ExtendedDateTime | undefined;
        to: PHPW.Mail.Model.MailParser.MailAddress[];
        from: PHPW.Mail.Model.MailParser.MailAddress[];
        cc: PHPW.Mail.Model.MailParser.MailAddress[];
        bcc: PHPW.Mail.Model.MailParser.MailAddress[];
        replyTo: PHPW.Mail.Model.MailParser.MailAddress[];
        messageId: string | undefined;
        inReplyTo: string | undefined;
        priority: string | undefined;
        size: number;
        tasks?: PHPW.Api.Routes.ComboboxModel.Aufgabe[];
        raw?: string | undefined;
    }
    export interface MailAddress {
        address: string | undefined;
        name: string;
    }
}
export namespace PHPW.Mail.Model.MailSender {
    export interface Address {
        name: string;
        address: string;
    }
    export interface Attachment {
        href?: string | undefined;
        cid?: string | undefined;
        filename?: string | undefined;
        content?: string | undefined;
        path?: string | undefined;
    }
    export interface Auth {
        user: string;
        pass: string;
    }
    export interface Input {
        transport: PHPW.Mail.Model.MailSender.Transport;
        mail: PHPW.Mail.Model.MailSender.Mail;
        returnRaw: boolean;
    }
    export interface Mail {
        from: PHPW.Mail.Model.MailSender.Address | string | undefined;
        to: undefined | Array<PHPW.Mail.Model.MailSender.Address | string>;
        cc: undefined | Array<PHPW.Mail.Model.MailSender.Address | string>;
        bcc: undefined | Array<PHPW.Mail.Model.MailSender.Address | string>;
        subject: string | undefined;
        text: string | undefined;
        html: string | undefined;
        attachments: undefined | PHPW.Mail.Model.MailSender.Attachment[];
        replyTo: PHPW.Mail.Model.MailSender.Address | undefined;
        inReplyTo: string | undefined;
        reference: string | undefined;
        priority: "high" | "normal" | "low" | undefined;
        headers: {[key: string]: string};
    }
    export interface Tls {
        minVersion: "TLSv1";
        rejectUnauthorized: boolean;
    }
    export interface Transport {
        host: string;
        port: number;
        secure: boolean;
        auth: PHPW.Mail.Model.MailSender.Auth;
        tls: PHPW.Mail.Model.MailSender.Tls;
    }
}
export namespace PHPW.Parcel.Model {
    export interface Carrier {
        name: string;
        display_name: string;
        services: string[];
        package_types: string[];
        additional_services: string[];
    }
    export interface ShipmentQuoteResponse {
        providerId: string;
        providerDisplayName: string;
        carrierId: string;
        carrierDisplayName: string;
        serviceId: string | number;
        serviceDisplayName: string;
        netPrice: number | undefined;
    }
}
export namespace PHPW.Price.Model {
    export interface Price {
        id?: number | undefined;
        minAmount: number;
        exactPrice: number;
        contact?: PHPW.Api.Routes.ComboboxModel.Contact | undefined;
        start?: PHPW.ExtendedDateTime | undefined;
        stop?: PHPW.ExtendedDateTime | undefined;
        lastUpdate?: PHPW.ExtendedDateTime | undefined;
        rabattierbar: boolean;
        description?: string | undefined;
        deleted: boolean;
    }
    export interface PriceContact extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        contact: PHPW.Api.Routes.ComboboxModel.Contact;
        article?: PHPW.Api.Routes.ComboboxModel.Article | undefined;
        price: PHPW.Price.Model.Price[];
        tax?: number | undefined;
        deleted: boolean;
    }
    export interface PriceGroup extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        name?: PHPW.Api.Routes.ComboboxModel.ArticleSellingPriceGroupName | undefined;
        article?: PHPW.Api.Routes.ComboboxModel.Article | undefined;
        price: PHPW.Price.Model.Price[];
        tax?: number | undefined;
        deleted: boolean;
    }
}
export namespace PHPW.Process.Model {
    export enum ProcessPositionType {
        "Article" = 0,
        "Discount" = 1,
        "Group" = 2,
        "Alternative" = 3,
        "Optional" = 4,
        "Text" = 5,
        "PageBreak" = 6,
        "Separator" = 7,
        "DoubleSeparator" = 8,
        "Subtotal" = 9,
        "TitleStart" = 10,
        "TitleEnd" = 11,
        "SetStart" = 12,
        "SetEnd" = 13,
        "CollectionStart" = 14,
        "CollectionEnd" = 15
    }
    export enum ProcessTypeArcType {
        "OFFER" = "offer",
        "CONFIRMATION" = "confirmation",
        "DELIVERY" = "delivery",
        "INVOICE" = "invoice"
    }
    export enum ProcessTypeCategory {
        "CORRECTION" = "correction",
        "INVOICE" = "invoice",
        "OFFER" = "offer",
        "ORDER" = "order",
        "CONFIRMATION" = "confirmation",
        "PROFORMA" = "proforma",
        "DELIVERY" = "delivery",
        "RETOURE" = "retoure"
    }
    export interface Interval extends PHPW.Api.Bodies.Model {
        id?: number | undefined;
        interval: number;
        processIds: number[];
        latestProcess?: PHPW.ExtendedDateTime | undefined;
    }
    export interface PositionPreis {
        exactPrice?: number | undefined;
        hiddenDiscounts: PHPW.Process.Model.PositionRabatt[];
        visibleDiscount?: PHPW.Process.Model.PositionRabatt | undefined;
        priceGroupName?: PHPW.Api.Routes.ComboboxModel.ArticleSellingPriceGroupName | undefined;
    }
    export interface PositionRabatt {
        count: number;
        name?: string | undefined;
    }
    export interface PositionShippingPlan extends PHPW.Api.Bodies.Model {
        shippingDate?: PHPW.ExtendedDateTime | undefined;
        shippingDateType?: PHPW.Entities.Enum.ProcessPositionShippingDateType | undefined;
        shippingType?: PHPW.Entities.Enum.ProcessPositionShippingType | undefined;
    }
    export interface PositionShippingPlanChange extends PHPW.Process.Model.PositionShippingPlan {
        positions: number[];
    }
}
export namespace PHPW.PushMessage.Model {
    export interface PushMessagePayload {
        id: number;
        guid: string;
        user: string;
        type: string;
        subtype: string;
        body: string;
    }
}
export namespace PHPW.Table.Profile.Model {
    export enum ColumnAlign {
        "L" = "L",
        "C" = "C",
        "R" = "R"
    }
    export enum ColumnSize {
        "XXS" = "XXS",
        "XS" = "XS",
        "S" = "S",
        "M" = "M",
        "L" = "L",
        "XL" = "XL",
        "XXL" = "XXL"
    }
    export interface Column extends PHPW.Api.Bodies.Model {
        name: string;
        nameOverwrite?: string | undefined;
        titleOverwrite?: string | undefined;
        visible: boolean;
        size: PHPW.Table.Profile.Model.ColumnSize;
        exactSize?: number | undefined;
        align: PHPW.Table.Profile.Model.ColumnAlign | undefined;
        type?: string | undefined;
        label?: string | undefined;
        searchable?: boolean | undefined;
        clearable?: boolean | undefined;
        selectable?: boolean | undefined;
    }
}
export namespace PHPW.User.Model {
    export interface UserGroup extends PHPW.Api.Bodies.Model {
        id: number | undefined;
        permissions: number[];
        name: string;
    }
}
